import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../Actions/UserActions';
import Seguridad from '../Dashboard/Profile/SeguridadPage'
import { LoadingMessage } from '../../Helpers/LoadingMessage';
import * as Messages from  '../../Helpers/Messages'
import { history } from '../../Helpers/History'
import { useParams } from 'react-router-dom';
import * as Constants from '../../Constants/Constants'

export const PasswordRecuperacion = () =>{
    
    const parameters = useParams();
    const username = parameters.email
    const password = parameters.token
    const dispatch = useDispatch()
    const userID = localStorage.getItem(Constants.TOKEN_SESSION_USER_ID)
    

    
    const loading    =  useSelector (state => state.userReducer.isProcessing )
    const error       =  useSelector (state => state.userReducer.isError )
    const isLoggedIn    =  useSelector (state => state.userReducer.loggedIn )
    

    useEffect(() => {
        if (isLoggedIn === true ) {
            history.push("/panel") 
        } else {
            console.log("changed but False")
            //check if Logged in 
            dispatch(actions.checkIfLogged())
        }
    }, [isLoggedIn])

    useEffect(()=>{
        if(isLoggedIn !== true){
            const dataFields = { username, password }
            dispatch(actions.initLoginAction(dataFields))
        }
    },[])

    return(
        <div className="pass-reset-container">
            <div className="pass-reset-box">
                 
                { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
                { loading ? <LoadingMessage /> : null }
                { isLoggedIn === true ? <Seguridad userID={userID} token={parameters.token} /> : null}
                { isLoggedIn === false ? (
                    <div>
                        <p>Este link ya no es válido</p>
                        <a href="/">Ir al inicio</a>
                    </div>
                ) : null}

            </div>
        </div>
        
    )
}

export default PasswordRecuperacion