import React, {useEffect, useState} from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom'
import CreacionPeriodo from './CreacionPeriodo'
import PeriodoMateriasPage from './PeriodoMateriasPage'
import HorariosPage from './HorariosPage2'
import * as HelperFunctions from '../../../Helpers/Functions';
import { history } from '../../../Helpers/History'
import { useSetState } from 'react-use';


export const SemestrePage = (props) => {

    
    const { match, path } = props
    const slug = HelperFunctions.slugify( props.location.pathname)
    const localStage = localStorage.getItem('PERIODO_STAGE')
    const [stage, setStage] = useState(localStage)
    const [component, setComponent] = useState(null)

    useEffect(()=>{
        if(stage === null || stage === undefined){
            localStorage.setItem('PERIODO_STAGE', 'creacion');
            setStage('creacion');
        }
    }, [])

    useEffect(()=>{
        console.log(stage)
        const newComponent = PageByStage(stage, showThisComponent)
        setComponent(newComponent)
    }, [stage])
    
    const showThisComponent = (newStage) => {
        setStage(newStage)
    }
    
    const PageByStage = (thisStage, showThisComponent) => {
        switch(thisStage) {
            case "creacion":
                return <CreacionPeriodo {...props} showThisComponent={showThisComponent}/>
            case "seleccion-materias":
                return <PeriodoMateriasPage {...props} showThisComponent={showThisComponent}/>
            case "seleccion-horarios":
                return <HorariosPage {...props} showThisComponent={showThisComponent}/>
            default:
                return <CreacionPeriodo  {...props} />
        }
    }

    
    
    return (
        <div className="container mgntop">
            {component}
        </div>
    )
}