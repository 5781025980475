import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../Actions/PeriodoActions'
import * as Constants from '../../../Constants/Constants'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
import * as HelperFunctions from '../../../Helpers/Functions';
import {DropdownButton, Dropdown} from 'react-bootstrap'
import * as Messages from  '../../../Helpers/Messages'

export const Horarios = ({handleSelection}) =>{
    
    //hooks
    const [claseSel, setClase]            = useState({})
    const [profesorSel, setProfesor]      = useState(null)
    const [diaSel, setDia]                = useState(null)
    const [horaSel, setHora]              = useState(null)
    const [aulaSel, setAula]              = useState(null)
    const [materiaSel, setMateria]        = useState(null)
    const [carrera, setCarrera]           = useState({id:0, titulo:""})
    const [semestres, setSemestres]       = useState([])
    const [isDisabled, setisDisabled]     = useState(true)
    const [semestresIDs, setSemestresIDs] = useState([])
    const dispatch                        = useDispatch();


    // useEffect(()=>{
        
    //     const loadMaterias = () => dispatch(actions.getMateriasByCarreraAction(1))
    //     loadMaterias()
    // }, [])

    
    //State
    const materias          = useSelector(state => state.periodoReducer.materias)
    const carreras          = useSelector(state => state.periodoReducer.carreras)
    const carrerasSem       = useSelector(state => state.periodoReducer.materiasSemestre)
    const loading           = useSelector(state => state.periodoReducer.isProcessing)
    const error             = useSelector(state => state.periodoReducer.error)
    const horas             = ['8:00','9:00','10:00','11:00','12:00','1:00']
    const aulas             = ['Aula1','Aula2','Aula3','Aula4','Aula5']
    const dias              = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes']
    const profesores        = ['Lara B','Padron R','Leon R']
    const programas         = [
        {id:1, hora:'8:00', aula: 'Aula2', materia:"diseno-grafico" ,titulo:'Materia I'},
        {id:2, hora:'8:00', aula: 'Aula5', materia:"diseno-grafico" ,titulo:'Materia II'},
        {id:3, hora:'10:00', aula: 'Aula4', materia:"diseño-industrial" ,titulo:'Materia III'},
        {id:4, hora:'10:00', aula: 'Aula3', materia:"ilustracion" ,titulo:'ilustracion'},
        {id:5, hora:'12:00', aula: 'Aula1', materia:"año-basico" ,titulo:'año-basico'},
    ]
    const [activeSemestre, setActiveSemestre] = useState(semestres[0])


    
    //Functions

    const onCarreraSelect = (e) => {
        e.preventDefault()
        const carreraSelected   = carrerasSem.find(car => car.carreraID === e.target.id)
        
        const semestres         = carreraSelected.semestres.map(sem => {
            const semestre ={
                semestre: sem.semestre,
                selected: false
            }
            return semestre
        })
        setCarrera(carreraSelected)
        setSemestres(semestres)
    }

    const onSemestresSelect = (e) => {
        e.preventDefault()
        const semestresNew = semestres.map(sem =>{
            if(sem.semestre == e.target.id){
                sem.selected = ! sem.selected
            }
            return sem
        })
        setSemestres(semestresNew)
        setisDisabled(false)
    }

    const addDataToClase = (e) =>{

        e.preventDefault()
        const propTitle = e.target.dataset.titulo
        const proValue = e.target.dataset.valor

        let newClase = claseSel
        newClase[propTitle] = proValue
        setClase(newClase)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const horario = {
            profesor: profesorSel,
            dia: diaSel,
            hora: horaSel,
            aula: aulaSel,
            materia: materiaSel,
        }
        // localStorage.setItem('SAVED_CARRERAS', carreras)
        console.log(horario)
    }

    const onPublicar = () => {
        console.log('Publicar')
    }

    const AulaRow = ({hora}) => {
        return aulas.map((aula, index )=> {
            const cell = programas.find(prog => prog.hora === hora && prog.aula === aula)
            
            const slug = cell ? HelperFunctions.slugify(cell.materia) : ""

            return (
                <td key={index} className="horario-celda">
                {cell ? (
                    
                        <div className={`horario ${slug}`}>
                            {cell.titulo}
                            {cell.hora}
                        </div>
                    
                ) : null }
                </td>
            )
        })
        
    }

    console.log(semestres)
    console.log(carrera)
    return(
        <div className="container horarios">
            <div>
                <h1>Horarios</h1>
            </div>
            <div className="row breadcrumbs">
                
                <div className="col-md-10 breadcrumbs-btns">
                    <div>
                        {carreras.map(car => <button 
                                                onClick={onCarreraSelect}
                                                className={`carrera-btn ${carrera.carreraID == car.id ? 'selected' : ''}`}
                                                id={car.id}
                                                key={car.id}>{car.titulo}</button>)}
                    </div>
                    <div>
                        {semestres.map(sem => <button 
                                                onClick={onSemestresSelect}
                                                className={`carrera-btn ${sem.selected ? 'selected' : ''}`}
                                                id={sem.semestre}
                                                key={sem.semestre}>Semestre {sem.semestre}</button>)}
                    </div>
                   
                </div>
                <div className="col-md-2">
                    <button disabled={isDisabled} className="publicar-btn" onClick={onPublicar}>Publicar</button>
                </div>
            </div>
            { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
            { loading ? <LoadingMessage /> : null }
            <div className="armemos">Armemos tu horario</div>
            <div className="row">
                
                <div className="col-md">
                    
                    <div className="opciones">
                        <div className="horarios-columns" id="dia">
                            <span className="title-span">Dia</span>

                            {dias.map((dia, index )=> (
                                <button disabled={isDisabled}
                                className={ dia == diaSel ? 'selected' : ""}
                                key={index} onClick={()=>setDia(dia)}>{dia}</button>
                                ))}
                           
                        </div>
                        <div className="horarios-columns" id="aula">
                            <span className="title-span">Salon</span>
                            
                            {aulas.map((aula, index )=> (
                                <button disabled={isDisabled}
                                className={ aulaSel == aula? 'selected' : ""}
                                key={index} onClick={()=>setAula(aula)}>{aula}</button>
                                ))}
                        </div>
                        <div className="horarios-columns" id="hora">
                            <span className="title-span">Hora</span>

                            {horas.map((hora, index) => (
                                <button disabled={isDisabled}
                                className={ hora == horaSel? 'selected' : ""}
                                key={index} onClick={()=>setHora(hora)}>{hora}</button>
                                ))}
                        </div>
                        <div className="horarios-columns" id="materia">
                            <span className="title-span">Materia</span>

                            {materias.map((materia, index) => (
                                <button disabled={isDisabled}
                                className={ materia == materiaSel? 'selected' : ""}
                                key={index} onClick={()=> setMateria(materia.titulo)}>{materia.titulo}</button>
                                ))}
                        </div>
                        <div className="horarios-columns" id="profesor">
                            <span className="title-span">Profesor</span>

                            {profesores.map((prof, index) => (
                                <button disabled={isDisabled}
                                className={ prof == profesorSel ? 'selected' : ""}
                                key={index} onClick={()=>setProfesor(prof)}>{prof}</button>
                                ))}
                        </div>
                    </div>
                    
                </div>
                <div className="col-md">
                    <table>
                        <thead>
                            <tr>
                                <td><strong>Hora</strong></td>
                                <td><strong>Aula1</strong></td>
                                <td><strong>Aula2</strong></td>
                                <td><strong>Aula3</strong></td>
                                <td><strong>Aula4</strong></td>
                                <td><strong>Aula5</strong></td>
                                <td><strong>Aula6</strong></td>
                            </tr>
                        </thead>
                        <tbody>
                            {horas.map((hora, index) => (
                                <tr key={index}>
                                    <td>{hora}</td>
                                    <AulaRow hora={hora}/>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="">
                <button disabled={isDisabled} className="guardar-horario" onClick={handleSubmit}>Guardar</button>
            </div>
        </div>
    )
}
export default Horarios