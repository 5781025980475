import React, { Fragment, useState, useEffect} from 'react';
import axios from 'axios';
import {LoadingMessage} from '../../../Helpers/LoadingMessage'
import * as Messages from  '../../../Helpers/Messages'
import * as Constants from '../../../Constants/Constants';
import {useDispatch, useSelector} from 'react-redux'
import { Link } from 'react-router-dom';
import * as actions from '../../../Actions/InscripcionesActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faEye } from '@fortawesome/free-solid-svg-icons'
import Progreso from './Progreso'

export const Inscripciones = ({handleClickPreview}) => {
    
    const [inscripcionesList, setInscripcionesList]= useState([])
    const [inputBuscar, setInputBuscar] = useState('')
    const dispatch = useDispatch()

    useEffect(()=>{
        const getInscripciones = () => dispatch(actions.getInscripcionesAllAction())
        getInscripciones()

    }, [])


    const inscripciones     = useSelector(state => state.inscripcionReducer.inscripciones)
    const loading           = useSelector(state => state.inscripcionReducer.isProcessingInscripciones)
    const error             = useSelector(state => state.inscripcionReducer.errorInscripciones)


    useEffect(()=>{
        setInscripcionesList(inscripciones)
    }, [inscripciones])
    


    //Functions

    const handleChange = (e) =>{
        const input = e.target.value
        const inputLower = input.toLowerCase()
        setInputBuscar(input)
        const inscripcionesList = inscripciones.filter(elem => elem.nombre.toLowerCase().includes(inputLower) || elem.apellido.toLowerCase().includes(inputLower))
        setInscripcionesList(inscripcionesList)

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(inputBuscar)
        
    }
    
    // handleResults(results){
    //     this.setState({ results })
    // }



    const renderResults = () => {
        if(inscripcionesList.length < 1 && !loading ) return <tr><td><p>No encontrado</p></td></tr>
        return (
            // inscripciones.map(estudiante => {
            
            inscripcionesList.map(estudiante => {
                return (                
                    <tr key={estudiante.id}>
                        <td>                             
                            <img
                            className="rounded-circle userpic-list"
                            src="/img/default_userpic.gif"
                            alt={`${estudiante.nombre.toLowerCase()} ${estudiante.apellido.toLowerCase()}`} 
                            />
                            <span className="capitalize">{estudiante.nombre.toLowerCase()} </span> 
                            <span className="capitalize">{estudiante.apellido.toLowerCase()}</span>
                        </td>
                        <td>
                            {/* <span class="badge badge-grafico">Gráfico</span> */}
                            <Progreso estudiante={estudiante}/>
                        </td>
                        <td className="d-flex">
                            <Link to={Constants.Link_inscripciones__edit+estudiante.id}>
                                <img className="action-icon" 
                                alt="Editar" 
                                src="../../icons/edit.svg" 
                                />
                            </Link>
                            <a href="#" 
                                onClick={() => handleClickPreview(estudiante)}
                                ><img className="action-icon" 
                                alt="Ver" src="../../icons/view.svg" 
                            /></a>
                            {/* <Link to={Constants.Link_inscripciones__edit+estudiante.cedula}><FontAwesomeIcon icon={faPencilAlt} size="1x"/></Link>
                            <Link to={Constants.Link_inscripciones__detail+ins.cedula}><FontAwesomeIcon icon={faEye} size="1x"/></Link> */}
                        </td>
                    </tr>

                )
            })
        )        
    }

    
        
    return (
        <Fragment>
            
            <h4 className="text-left">Inscripciones en curso</h4> 
            
            <form onSubmit={handleSubmit}>
                <div className="">
                    <input 
                    className="search-bar"
                    type="text"
                    placeholder="Buscar nombre"
                    
                    onChange={handleChange} />
                    {/* <button type="submit" >Buscar</button> */}
                </div>
            </form>
            
            <table className="table table-hover list table-4 table-fixed">
                <thead>
                    <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Progreso</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
            </table>
            <div className="wrapper-for-overflow">
                <table className="table table-hover list table-4 table-overflow ">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Progreso</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody className="inscripciones-list">
                        { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null } 
                        { loading ? <LoadingMessage /> : null }
                        {  inscripciones.length === 0 && !loading
                            ?<tr>
                                <td>No hay Inscripciones en curso</td>
                            </tr>
                            : renderResults()
                        } 
                    </tbody>
                </table>
            </div>
             {/*   <tbody>
                    {  inscripciones.length === 0
                        ?<tr>No hay Inscripciones en curso</tr>
                        :renderResults()
                    }     
                </tbody>
            </table>                 */}
        </Fragment>
    )
    
}
export default Inscripciones;