import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../Actions/EstudiantesActions'
import * as notasActions from '../../../Actions/NotasActions'
import EstudiantesRow from './EstudiantesRow'
import { Table } from 'react-bootstrap'
import * as Messages from  '../../../Helpers/Messages'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
import * as elems from '../../../AFakeData/myfakedata'
import { useMouse } from 'react-use'

export const EstudiantesList = ({ clases, materiaID }) => {
    const dispatch = useDispatch()
    const initialLength = 4
    const [estuQuantity, setEstuQuantity] = useState(initialLength)
    useEffect(() => {
        const getEstudiantes = () => dispatch(actions.getEstudiantesByMateria(materiaID, estuQuantity))
        const getNotas = () => dispatch(notasActions.getNotasByMateria(materiaID))
        getEstudiantes()
        getNotas()
    }, [estuQuantity])

    
    const estudiantesListArray = useSelector(state => state.estudianteReducer.estudiantes)
    
    //const estudiantesListArray = elems.estudiantesInfo.estudiantes //estudiantesList ? Object.values(estudiantesList) : [] //Esta funcion permite tomar los valores del obj de retorno y agregarlos a un array
    


    const notas = elems.notas //useSelector(state => state.notaReducer.notas)
    const estudiantesTotal = elems.estudiantesInfo.estudiantesTotal //estudiantesList ? estudiantesListArray.length : 0
    const error = useSelector (state => state.estudianteReducer.error)
    const loading = useSelector(state => state.estudianteReducer.isProcessing)

    
    let estuListRef = React.createRef() //este es una prueba

    const { docX, docY } = useMouse(estuListRef)

    const toggleEstudiantesQty = () => {
        if(estuQuantity === estudiantesTotal){
            setEstuQuantity(initialLength)
        }
        else{
            setEstuQuantity(estudiantesTotal)
        }
    }
    return(
        <div className="">
            
            { loading ? <LoadingMessage /> : null }
            { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null }
                
            { estudiantesListArray.length === 0 ? <div className="materia-message">{Messages.ESTUDIANTES_LIST__EMPTY}</div> : (
            <div id="estudiantes-list" className="">
                    
                <Table className="notas" bordered hover ref={estuListRef}>
                <thead>
                    <tr>
                        <td></td>
                        {clases.map(clase => (clase.evaluada ? (<td className="bordered">{clase.id}</td>):null))}
                    </tr>
                </thead>
                <tbody>
                    {estudiantesListArray.slice(0, estuQuantity).map(estudiante => {
                            let estudianteNotas = []
                            notas.map(nota => {
                                if(nota.estudianteID === estudiante.id){
                                    estudianteNotas.push(nota)
                                }
                            })
                            return (
                                < EstudiantesRow clases={clases} estudiante={estudiante} notas={estudianteNotas} />
                            )
                    })}
                </tbody>
                
                
                </Table>
                <div class="button-wrapper">    
                    <button onClick={toggleEstudiantesQty}>+</button> 
                </div>
            </div>)}
        </div>
    )
}