import React, { Component, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import DanteEditor from  'Dante2'
import * as Constants from '../../../Constants/Constants';

import * as actions from '../../../Actions/ClasesActions'
import PropTypes from 'prop-types'
import * as Messages from  '../../../Helpers/Messages'
import { useParams, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { LoadingMessage } from '../../../Helpers/LoadingMessage';


export const ClaseDetail = (clase) => {

   const history   = useHistory()
   const parameters = useParams();
   
   const dispatch = useDispatch();
   const claseID = parameters.id
   
   //Load Clase Info
   useEffect(() => {
    const getClase = () => dispatch(actions.getClaseByIDAction(claseID))        
    getClase()
}, [])
   
    const claseInfo  = useSelector( state => state.claseReducer.clase )

    const claseInfoArray = Array.from(claseInfo)
    const error     = useSelector (state => state.claseReducer.error)
    const loading   =  useSelector (state => state.claseReducer.isProcessing ) 
    const saving     =  useSelector (state => state.claseReducer.saving ) 

    //Editar Clase

    const startEditClase = (clase) => {
        dispatch ( actions.editClasesAction(clase))
    }

    
      

    //New state from 'clase'
    const [claseEditing, updateClase] = useState({
        editor: '' //evaluar naming
    });  
    //Get 'content' State
    const claseFromState  = useSelector( state => state.claseReducer.clase)

    //Fill state
    useEffect( () => {
        updateClase(claseFromState)
    }, [claseFromState]);
    //Read content from DanteEditor
    const onSaveHandler = (wholeThing) => {
        dispatch (actions.editClaseAction(wholeThing))
        updateClase({
           // ...claseEditing,
           wholeThing})
    }


    
    //Editing Switch
    const [editing, editingSwitch] = useState(true)

    return (
        <div className="container mgntop">
            { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
            { loading ? <LoadingMessage /> : null }
            { saving ? <p className="success alert-warning">Última edición</p>: null }  
            { claseInfoArray.map( clase => (
                <>
                    <h1>{clase.nombre}</h1>
                    <button
                    type="button"
                       onClick={() => editingSwitch(!editing) } >Editar
                    </button>                    1
                    <hr />                                        
                    <DanteEditor                            
                        // content={clase.contenido}
                        read_only={editing}
                        data_storage={{
                            url: Constants.ApiURL+`/clase/${clase.id}/edit`,
                            method: "POST",
                            interval: 1500,
                            save_handler: (editorContext, content) => {
                                const wholeThing = {
                                    id: clase.id,
                                    title: clase.nombre,
                                    content: content
                                }
                                onSaveHandler(wholeThing)
                            }
                            //withCredentials: false,
                            //crossDomain: true,
                            //save_handler: saveHandler,
                        }}                        
                    />                       
                               
                    <hr />
                    
                    <p>Imagen principal o Video</p>
                    <p>Contenido de la clase</p>
                    <hr />
                    ¿Tendrá evaluación ?
                    Asistencia
                </>
            )) }
        </div>
    )

}