import React, {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../Actions/UserActions'
import { LoadingMessage } from '../../Helpers/LoadingMessage';
import * as Messages from  '../../Helpers/Messages'

export const LoginRecuperacion = () => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')


    const handleChange = (e) => {
        const value = e.target.value
        setEmail(value)
        
    }

    const handleSubmit = () =>{
        dispatch(actions.recuperacionEmailAction(email))
    }
    
    const loading = useSelector(state => state.userReducer.isProcessing)
    const error = useSelector(state => state.userReducer.isError)
    const sent = useSelector(state => state.userReducer.isEmailSent)

    return(
        <>
        <div className="pass-reset-container">
		        <div className="pass-reset-box recuperacion">
                    
                <h1>¿Cual es tu email?</h1>
                <form className="profile-form" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input onChange={handleChange} id="email" name="email" type="email" />
                    </div>
                    <button className="btn btn-primary btn-recuperacion" type="submit">Recuperar</button>
                </form>
                { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
                { loading ? <LoadingMessage /> : null }
                { sent === true ? <p className="alert alert-success">Email enviado exitosamente. Revisa tu bandeja de entrada para proceder</p> : null}
                       
            </div>
        </div>
        
     </>
    )
}

export default LoginRecuperacion