import * as actions from '../Actions/ActionTypes'

const initialState = {
    mensajes: [],
    activeChat: {},
    content: "",
    isProcessing: false,
    error:false,
    isProcessingLists: false,
    errorLists:false,
    profesores:[],
    estudiantes:[],
    isSending:false,
    errorSending:false,
    successfullySent:false,
    //esto es temporal
    fakeMensajes:[{id:1000, senderID:8, receiverID:20, mensaje:'Esta es una prueba'}],
    fakeActiveChat:{}
};
  
export default function mensajesReducer(state = initialState, action) {
    switch (action.type) {
        case actions.MENSAJES_GET_BY_USERS_INIT:
            return {...state,                
                isProcessing: action.payload 
            }
        case actions.MENSAJES_GET_BY_USERS_SUCCESS:
            return {...state,                
                isProcessing: false,
                mensajes: action.payload
            }
        case actions.MENSAJES_GET_BY_USERS_ERROR:
            return {...state,                
                isProcessing: false,
                error: action.payload
            }
        case actions.MENSAJE_SEND_INIT:
            return {...state,                
                isSending: action.payload 
            }
        case actions.MENSAJE_SEND_SUCCESS:
            return {...state,                
                isSending: false,
                successfullySent: action.payload
            }
        case actions.MENSAJE_SEND_ERROR:
            return {...state,                
                isSending: false,
                errorSending: action.payload
            }
        case actions.MENSAJES_ACTIVE_CHAT_INIT:
            return {...state,
                isProcessing: action.payload,
            }
        case actions.MENSAJES_ACTIVE_CHAT_SUCCESS:
            return {...state,                
                isProcessing: false,
                activeChat: action.payload
            }
        case actions.MENSAJES_ACTIVE_CHAT_ERROR:
            return {...state,                
                isProcessing: false,
                error: action.payload
            }
        case actions.MENSAJES_GET_PROFESORES_INIT:
            return {...state,
                isProcessingLists: action.payload,
            }
        case actions.MENSAJES_GET_PROFESORES_SUCCESS:
            return {...state,                
                isProcessingLists: false,
                profesores: action.payload
            }
        case actions.MENSAJES_GET_PROFESORES_ERROR:
            return {...state,                
                isProcessingLists: false,
                errorLists: action.payload
            }
        case actions.MENSAJES_GET_ESTUDIANTES_INIT:
            return {...state,
                isProcessingLists: action.payload,
            }
        case actions.MENSAJES_GET_ESTUDIANTES_SUCCESS:
            return {...state,                
                isProcessingLists: false,
                estudiantes: action.payload
            }
        case actions.MENSAJES_GET_ESTUDIANTES_ERROR:
            return {...state,                
                isProcessingLists: false,
                errorLists: action.payload
            }

        //Zona de prueba
        case "SEND_FAKE_MENSAJE":
            return {...state,
                fakeMensajes:[...state.fakeMensajes, action.payload]
            }
        case "GET_FAKE_CHAT":
            return {...state,
                fakeActiveChat:action.payload
            }
        //
        default:
            return state;
    }
}