import * as actions from './ActionTypes';
import clientAxios from './clientAxios';
import * as elems from '../AFakeData/myfakedata'


export const getProfesoresAll = () => {
    return async(dispatch) => {
        dispatch(getProfesores())
        try {
            const response = await clientAxios.get('/profesores')
            console.log(response)
            dispatch(getProfesoresSuccess(response.data.data))
        } catch(error){
            console.log(error)
            dispatch(getProfesoresError())
        }
    }
}

const getProfesores = () => ({
    type: actions.PROFESORES_GET_ALL_INIT, 
    payload: true
})

const getProfesoresSuccess = (results) => ({
    type: actions.PROFESORES_GET_ALL_SUCCESS,
    payload: results
})

const getProfesoresError = () => ({
    type: actions.PROFESORES_GET_ALL_ERROR,
    payload: true
})