import React, { Component, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import fakedata from '../../../AFakeData/fakedata.json'
import MateriaBox from './MateriaBox'
import * as actions from '../../../Actions/MateriasActions'
import PropTypes from 'prop-types'
import * as Constants from '../../../Constants/Constants'
import * as Messages from  '../../../Helpers/Messages'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
import * as HelperFunctions from '../../../Helpers/Functions';

import FadeIn from 'react-fade-in';

export const MateriasPage = () => {
        
    const dispatch = useDispatch();
    const userID = localStorage.getItem(Constants.TOKEN_SESSION_USER_ID)

    useEffect(() => {

        const cargarMaterias = () => dispatch(actions.getMateriasByUserAction(userID))
                                                                                
        cargarMaterias()
    }, [])

    const materiasListArray  = useSelector( state => state.materiaReducer.materias )
   
    //const materiasListArray = Object.values(materiasList) //Array.from(materiasList) // ESTA FUNCION me permitió extraer el array del resultad para que funcionara .map
    
    const error     = useSelector (state => state.materiaReducer.error)
    const loading   = useSelector (state => state.materiaReducer.isProcessing )
    
    materiasListArray.propTypes = { 
        materias: PropTypes.array
    }

    return (
        <div className="container mgntop">  
            <h1 className="hide-title">Materias</h1>
            <FadeIn>
            <h3 className="main-message">Selecciona una <span className="bigger">Materia</span> para comenzar</h3>  
            </FadeIn>
           
            { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
            { loading ? <LoadingMessage /> : null }
            
            <div className="row">
               {    materiasListArray.length === 0 ? Messages.MATERIAS_LIST__EMPTY  : (
                        materiasListArray.map( materia => (
                            <MateriaBox 
                                key={materia.id} 
                                materia={materia}
                                userID={userID}
                            />
                            ))
                    )                            
                }                              
            </div>
        </div>
    )
    
}