import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const TestCarousel = (data) => {

    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 3,
            partialVisibilityGutter: 40
            },
            mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
            },
            tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 2,
            partialVisibilityGutter: 30
        }
    }
    return(
            <Carousel 
                arrows
                autoPlaySpeed={3000}
                className=""
                containerClass="container-with-dots"
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
                >
                <div>
                    <img 
                        src="/img/default.jpg"
                        className="img-fluid"
                        alt="test-carousel"
                        />1 
                </div>
                <div>
                    <img 
                        src="/img/default.jpg"
                        className="img-fluid"
                        alt="test-carousel"
                        /> 2
                </div>
                <div>
                    <img 
                        src="/img/default.jpg"
                        className="img-fluid"
                        alt="test-carousel"
                        /> 3
                </div>
                <div>
                    <img 
                        src="/img/default.jpg"
                        className="img-fluid"
                        alt="test-carousel"
                        /> 4
                </div>
                <div>
                    <img 
                        src="/img/default.jpg"
                        className="img-fluid"
                        alt="test-carousel"
                        /> 5
                </div>
                <div>
                    <img 
                        src="/img/default.jpg"
                        className="img-fluid"
                        alt="test-carousel"
                        /> 6
                </div>
            </Carousel>    
    )
}