import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../Actions/UserActions'
import * as roles from '../../../Constants/Roles'
import * as Constants from '../../../Constants/Constants'
import PicEditor from './ProfileUploader'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
import * as Messages from  '../../../Helpers/Messages'
import InfoEstudiante from './infoEstudiante'
import InfoProfesor from './infoProfesor'

export const ProfilePage = ({ userData, isEstudiante, isEditable, userID, closeModalPic, role }) => {


    
    const dispatch        = useDispatch()
    const [data, setData] = useState(userData)

    const userDataKeys    = Object.keys(data)
    
    useEffect(()=>{
        setData(userData)
    }, [userData])

    const loading    =  useSelector (state => state.userReducer.isProcessing )
    const saving     =  useSelector (state => state.userReducer.savingData )
    const error      =  useSelector (state => state.userReducer.isError )
    const dataSaved  =  useSelector (state => state.userReducer.dataSaved )


    useEffect(()=>{
        if(dataSaved){
            setTimeout(()=>{
                dispatch(actions.saveUserDataToFalse())
            }, 3000)
        }
    }, [dataSaved])


    const onChange = (e) => {
        e.preventDefault()
        const property    = e.target.id
        const value       = e.target.value
        const newData     = data
        newData[property] = value
        setData(newData)
    }


    const onChangeFake = (e) => {
        e.preventDefault()
    }


    const handleSubmit = (e) =>{
        e.preventDefault()
        console.log(data)
        dispatch(actions.saveUserDataAction(userID,data))
    }

    return (
            <div className="profile-form">
            { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
            { dataSaved ? <p className="alert alert-success">Guardado</p> : null}
            { loading ? <LoadingMessage /> : null }
            { saving ? <LoadingMessage /> : null }
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="info-column col-12 col-sm-6">
                        <div className="title">Datos del estudiante</div>
                        {/* {userDataKeys.map((key, index)=>(
                            <div key={index} className="input-group">
                                <label htmlFor={key} className="form-label">{key.toUpperCase()}</label>
                                <input id={key} disabled={!isEditable} name={key} 
                                        value={data[key]} 
                                        type="text" onChange={onChange} />
                            </div>
                        ))} */}
                        {userDataKeys.map((key, index)=>{
                            if(key !== "imagen" && key!== "curriculum"){
                                return(
                                    <div key={index} className="input-group">
                                        <label htmlFor={key} className="form-label">{key.toUpperCase()}</label>
                                        <input id={key} disabled={!isEditable} name={key} 
                                            value={data[key]} 
                                            type="text" onChange={onChange} />
                                    </div>
                                )
                            } else {
                                return null
                            }
                            
                        })}
                        
                        
                    </div>
                    <div className="info-column col-12 col-sm-6">
                        {isEstudiante ? <InfoEstudiante isEditable={isEditable} onChangeFake={onChangeFake} /> : <InfoProfesor isEditable={isEditable} />}

                        <button type="submit">Guardar</button>
                    </div>
                </div>  
                
                </form>
            </div>   
        
    )
}