import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
//import { history } from '../../../Helpers/History' this will refresh

import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../Actions/ClasesActions'
import Swal from 'sweetalert2';

export const ClaseBox = ({ clase, parent }) => {

    console.log(parent)
    const dispatch  = useDispatch();
    const history   = useHistory() // use this if not using Helper History

    const confirmEliminarClase = (id) =>{
        //CONFIRMAR ACCION 
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
                //PASAR AL ACTION
                dispatch(actions.deleteClasesAction(id))
            }
        })        
    }

    /*const gotoEditRedirect = (clase) => {
        dispatch( actions.editClasesAction (clase))
        history.push(`/panel/clase/${clase.id}`)
    }*/
    

    return (
        <div className="">
            <div className="clase">
                {/*<button
                        type="button"
                        onClick={ () => gotoEditRedirect(clase) }>Editar
                </button>*/}
                <Link 
                to={`/panel/${parent.slug}/clase/${clase.id}`}>
                    <div className="row align-items-center">
                        <div className="col-sm-6 nopadding">
                            {clase.imagen ? (
                            <img 
                                src={clase.imagen}
                                className="img-fluid"
                            />
                            ) : (
                            <img 
                            src="/img/default.jpg"
                            className="img-fluid"
                            />)
                            } 
                        </div>
                        <div className="col-sm-6 text-left text-col">
                            <div class="title">{clase.nombre}</div> 
                            <div class="date">Creado el {clase.creation_date}</div>     
                        </div>
                    </div>              
                </Link>                                    
                <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => confirmEliminarClase(7)}>borrar</button>
            </div> 
            <br></br>
        </div>
    )           
}

export default ClaseBox