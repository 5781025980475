import React from 'react';
import { TestCarousel } from '../Materias/TestCarousel'
export const DashEstudiantePage = (props) => {

    return (
        <div className="container">
            <h2>Estudiante Welcome</h2> 
            <TestCarousel />     
        </div>
    )
}