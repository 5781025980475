import React, { useState } from 'react';
import { Estudiantes } from './Estudiantes';
import { EstudiantePreview } from './EstudiantePreview';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Draggable from 'react-draggable'
import InscripcionAdd from '../Inscripciones/InscripcionAdd';

export const EstudiantesAdminPage = (props) => {
    
    const [selectedEstudiante, sendPreview] = useState({
       // estudiante: null
    }); 

    const getSelectedEstudiante = (estudiante) => {
        sendPreview(estudiante)
    }

    // //Modal
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);


    return (
        <>  
            {/* <Modal 
                show={show} 
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdropClassName="no-bg"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <InscripcionAdd />
                </Modal.Body>
                
            </Modal> */}

            {/* <div className="col-12 col-md-8 text-right"> 
                <a href="#" className="btn btn-action" onClick={handleShow}>
                    <img className="action-icon" 
                        alt="Editar" 
                        src="../../icons/add.svg" 
                    />
                    Inscribir Alumno
                </a>
            </div> */}
            <div className="container nopadding ">
                <div className="row center-content">
                    <div className="col-12 col-md-8 h-100">
                        <h4 className="text-left">Estudiantes</h4>
                        <Estudiantes handleClickPreview={ getSelectedEstudiante } />                    
                        <hr/>
                    
                    </div>
                    <div className="col-12 col-md-4">
                        <EstudiantePreview estudiante={ selectedEstudiante } admin={true} />
                    </div>

                </div>
            </div>
        </>
    )
}