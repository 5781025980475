import * as actions from './ActionTypes';
import clientAxios from './clientAxios';




export function getCarrerasAllAction(){
    return async (dispatch) => {
        dispatch(descargarCarrerasAll())
        
        try {
            const response = await clientAxios.get('/carreras')
            console.log(response)
          
            dispatch(descargarCarrerasAllExitosa(response.data.data.results)) 

        } catch (error) {
            dispatch(descargarCarrerasAllError())
            console.log(error)
        }
    }
}
const descargarCarrerasAll = () => ({
    type: actions.PERIODO_GET_CARRERAS_ALL_INIT,
    payload: true 
})

const descargarCarrerasAllExitosa = (results) => ({
    type: actions.PERIODO_GET_CARRERAS_ALL_SUCCESS,
    payload: results
})

const descargarCarrerasAllError = () => ({
    type: actions.PERIODO_GET_CARRERAS_ALL_ERROR,
    payload: true
})

export function savePeriodoAction(){
    return async (dispatch) => {
        dispatch(savePeriodo())
        
        try {
            const response = await clientAxios.post('/periodo')
            console.log(response)
          
            dispatch(savePeriodoExitosa(response.data.data.results.mensaje))

        } catch (error) {
            dispatch(savePeriodoError())
            console.log(error)
        }
    }
}
const savePeriodo = () => ({
    type: actions.PERIODO_CREATE_NEW_INIT,
    payload: true 
})

const savePeriodoExitosa = (results) => ({
    type: actions.PERIODO_CREATE_NEW_SUCCESS,
    payload: true
})

const savePeriodoError = () => ({
    type: actions.PERIODO_CREATE_NEW_ERROR,
    payload: true
})



export const setCarrerasAllLocallyAction = (carreras) => ({
    type: actions.PERIODO_GET_CARRERAS_ALL_LOCALLY,
    payload: carreras
})

export function getProgramasAllAction(){
    return async (dispatch) => {
        dispatch(getProgramasAll())
        
        try {
            const response = await clientAxios.get('/programas')
            console.log(response)
          
            dispatch(getProgramasAllExitosa())
            console.log(response.data.data.results)

        } catch (error) {
            dispatch(getProgramasAllError())
            console.log(error)
        }
    }
}

const getProgramasAll = () => ({
    type: actions.PERIODO_CREATE_NEW_INIT,
    payload: true 
})

const getProgramasAllExitosa = (results) => ({
    type: actions.PERIODO_CREATE_NEW_SUCCESS,
    payload: true
})

const getProgramasAllError = () => ({
    type: actions.PERIODO_CREATE_NEW_ERROR,
    payload: true
})


export function getMateriasByCarreraAction(carreraID){
    return async (dispatch) => {
        dispatch(getMateriasByCarrera())
        
        try {
            const response = await clientAxios.get(`/carreras/${carreraID}`)
            console.log(response)
          
            dispatch(getMateriasByCarreraExitosa(response.data.data.results))

        } catch (error) {
            dispatch(getMateriasByCarreraError())
            console.log(error)
        }
    }
}

const getMateriasByCarrera = () => ({
    type: actions.MATERIAS_GET_BY_CARRERA_INIT,
    payload: true 
})

const getMateriasByCarreraExitosa = (results) => ({
    type: actions.MATERIAS_GET_BY_CARRERA_SUCCESS,
    payload: results
})

const getMateriasByCarreraError = () => ({
    type: actions.MATERIAS_GET_BY_CARRERA_ERROR,
    payload: true
})


export function getMateriasBySemestreAction(carreraID){
    return async (dispatch) => {
        dispatch(getMateriasBySemestre())
        
        try {
            const response = await clientAxios.get(`/carreras/${carreraID}/periodo/${1}`)
            console.log(response)
            const carreraObj = {carreraID: carreraID, semestres: response.data.data.results, show: true}
            dispatch(getMateriasBySemestreExitosa(carreraObj)) //response.data.data.results

        } catch (error) {
            dispatch(getMateriasBySemestreError())
            console.log(error)
        }
    }
}

const getMateriasBySemestre = () => ({
    type: actions.MATERIAS_GET_BY_SEMESTRE_INIT,
    payload: true 
})

const getMateriasBySemestreExitosa = (results) => ({
    type: actions.MATERIAS_GET_BY_SEMESTRE_SUCCESS,
    payload: results
})

const getMateriasBySemestreError = () => ({
    type: actions.MATERIAS_GET_BY_SEMESTRE_ERROR,
    payload: true
})


export const setActualCarrera = (carrera) => ({
    type: 'SET_CARRERA',
    payload: carrera
})

export const setAllMaterias = (carrera) => ({
    type: 'SET_ALL_MATERIAS',
    payload: carrera
})


export const setNewCarrerasSemestres = (newCarrerasArray) => ({
    type:'SET_NEW_CARRERA_SEMESTRES',
    payload:newCarrerasArray
})

export const setCarreraHorario = (carrera) => ({
    type: 'CARRERA_HORARIO',
    payload: carrera
})
export const setSemestresHorario = (semestres) => ({
    type: 'SEMESTRES_HORARIO',
    payload: semestres
})
