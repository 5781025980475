import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../Actions/PeriodoActions'
import * as Constants from '../../../Constants/Constants'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
import * as Messages from  '../../../Helpers/Messages'
import Button from './Button'

export const CreacionPeriodo = ({showThisComponent}) =>{

    const userID = localStorage.getItem(Constants.TOKEN_SESSION_USER_ID)
    
    //hooks
    const dispatch = useDispatch()
    const [carrerasArray, setCarrerasArray] = useState([])

    useEffect(()=>{
        const cars = getSavedCarreras()
        const loadCarreras = () => dispatch(actions.getCarrerasAllAction())
       
        if(cars){
            const loadCarrerasLocally= () => dispatch(actions.setCarrerasAllLocallyAction(cars.carreras))
            loadCarrerasLocally()
        } else{
            loadCarreras()
        }

    }, [])


    //State
    const carreras = useSelector(state => state.periodoReducer.carreras)
    
    const loadingCarreras   = useSelector(state => state.periodoReducer.isProcessingCarreras)
    const errorCarreras     = useSelector(state => state.periodoReducer.errorCarreras)
    const loadingSaved      = useSelector(state => state.periodoReducer.isProcessing)
    const errorSaved        = useSelector(state => state.periodoReducer.error)
    

    useEffect(() => {
        
        setCarrerasArray(carreras)
    }, [carreras])



    //Functions
    const handleSubmit = (e)=>{
        e.preventDefault()
        const formData = new FormData(e.target)

        
        const dataCreacion ={
            "nombre": formData.get("nombre"),
            "inicio":formData.get("inicio"),
            "finalizacion": formData.get("finalizacion"),
            "carreras": carrerasArray
        }
        const selectedCarreras = {
            "carreras": carrerasArray.filter(car => car.selected === true )
        }

        
        localStorage.setItem('PERIODO_DATA', JSON.stringify(dataCreacion))
        localStorage.setItem('SELECTED_CARRERAS', JSON.stringify(selectedCarreras))
        localStorage.removeItem('PERIODO_STAGE');
        localStorage.setItem('PERIODO_STAGE', 'seleccion-materias')
        showThisComponent('seleccion-materias')
    }

    const getSavedCarreras = () => {
        const data = JSON.parse(localStorage.getItem('PERIODO_DATA_PASO_1'))
        return data
    }

    
    const addCarrera = (e) => {
        e.preventDefault()
        const newCarreras = carreras.map((car, index)=>{

            if(car.id === e.target.id){
                car['selected'] ? car['selected'] = false : car['selected'] = true
            }
            return car
        })
        
        setCarrerasArray(newCarreras)
    }
    

    const addAllCarreras = (e) => {
        

        e.preventDefault()
        const newCarreras = carreras.map((car, index)=>{
            
            car['selected'] ? car['selected'] = false : car['selected'] = true
            return car
        })
    
        setCarrerasArray(newCarreras)
    }
    
    const goBack = () => {
        window.history.back();
    }
    

    return(
        <div className="container creacion-periodo periodo">
            <div className="row first-step">
                <div className="col-md welcome-box">
                    <div className="welcome-title">Vamos a configurar el próximo semestre</div>
                    <div className="welcome-message">Aqui haremos la apertura de carreras para la preinscripcion</div>
                    <div className="welcome-message">Asignar profesores a las Materias</div>
                    <button className="back-button" onClick={goBack}><div className="icon"></div>Me equivoqué, no queria entrar aquí</button>
                </div>
                <div className="col-md creacion-data">
                    { errorSaved ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
                    { loadingSaved ? <LoadingMessage /> : null }
                    <form onSubmit={handleSubmit}>
                        <div className="subtitles">¿En que fecha inciara el semestre</div>
                        <div className="input-group">
                            <label htmlFor="nombre" className="form-label">Nombre</label>
                            <input className="form-control" type="text" id="nombre" name="nombre" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="inicio" className="form-label">Inicio</label>
                            <input className="form-control" type="number" id="inicio" name="inicio" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="finalizacion" className="form-label">Finalización</label>
                            <input className="form-control" type="number" id="finalizacion" name="finalizacion" />
                        </div>
                        <div className="carreras-box">
                            <div className="subtitles">¿Cuales Carreras incluiras</div>
                            { errorCarreras ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
                            { loadingCarreras ? <LoadingMessage /> : null }
                            { loadingCarreras || errorCarreras ? null : (
                                <>
                                <button onClick={addAllCarreras} id="all" className="carreras-btn" >Seleccionar todas</button>
                                {carrerasArray.map(car => <button key={car.id} onClick={addCarrera} id={car.id}  
                                className={car.selected ? 'carreras-btn selected' : 'carreras-btn'} >{car.titulo}</button>)}
                                
                                {/* {AllCarrerasButtons(carreras, carrerasSelected)} */}
                                
                                </>
                            )}
                            
                        </div>
                        <button type="submit" className="carreras-btn">Siguiente</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreacionPeriodo