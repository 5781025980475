import React from 'react';
import * as Messages from  '../../../Helpers/Messages'

export const EmptyChat = ({ user }) => {
    return(
        
        <div className="info-wrapper">
            <div className="non-selected-box">
                <p className="non-selected">{Messages.NO_SELECTED_CONVERSATION}</p>
            </div>
        </div>
        
    )
}

export default EmptyChat