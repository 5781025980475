import React,  { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import * as Constants from '../../../Constants/Constants'
import * as actions from '../../../Actions/ClasesActions'
import * as Messages from '../../../Helpers/Messages'
import { LoadingMessage } from '../../../Helpers/LoadingMessage'
import { BackButton } from '../../default/BackButton'
import DanteEditor from  'Dante2'
import Modal from 'react-bootstrap/Modal'


export const ClaseDetailEstudiante = (clase) => {

    const history       = useHistory();
    const parameters    = useParams();
    const dispatch      = useDispatch();

    const claseID = parameters.id;

    //Load Clase Info
    useEffect(() =>{
        const loadClase = () => dispatch(actions.getClaseByIDAction(claseID))
        loadClase()
    },[])

    const claseInfo = useSelector ( state => state.claseReducer.clase )
    const claseInfoArray = Array.from( claseInfo )

    //UI signals
    const error     = useSelector( state => state.claseReducer.error )
    const loading   = useSelector( state => state.claseReducer.isProcessing )

    //Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
        { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
        { loading ? <LoadingMessage /> : null }
        { claseInfoArray.map( clase => (
            <>
                <div className="container-fluid nopadding main-clase">
                    <div className="row nopadding">
                        <div className="col-sm-3 summary">
                            <div className="clase-info">
                                <BackButton
                                    to={`/panel/materia/${clase.materia.id}`}
                                /> 
                                <div className="materia-title">{clase.materia.title}</div>
                                <div className="especialidad">{clase.materia.especialidad}</div>
                                <ul className="clases-timeline">
                                    <li>Clase 1</li>
                                    <li>Clase 2</li>
                                </ul>
                                <div className="asignacion">
                                    <h4>Asignación</h4>
                                    Este es el detalle de la asignación
                                </div>
                            </div>
                        </div>
                        <div className="col content-col">
                            <div className="featured-image">
                                {/*<img src="/img/default.jpg" className="img-fluid" />*/}
                            </div>
                            <div className="actions-area">
                                <a href="#" className="btn btn-action" onClick={handleShow}>
                                    <img className="action-icon" 
                                        alt="Editar" 
                                        src="../../../icons/raise-hand.svg" 
                                    />
                                    Hacer una pregunta
                                </a>
                                <a href="#" className="btn btn-action" onClick={handleShow}>
                                    <img className="action-icon" 
                                        alt="Editar" 
                                        src="../../../icons/add-file.svg" 
                                    />
                                    Enviar asignacion
                                </a>
                            </div>
                            <div className="clase-text">

                                <div class="date">Creado el {clase.creation_date}</div> 
                                <h1 className="title">{clase.title}</h1>
                                <div className="content">
                                    <DanteEditor                            
                                    content={clase.content}
                                    read_only={true}                                                   
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

                {/* Modal */}
                <Modal 
                    show={show} 
                    onHide={handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdropClassName="no-bg"
                    >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        X
                    </Modal.Body>
                    
                </Modal>
            </>
        )) 
        }
        </>
    )
}