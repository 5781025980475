import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

class AlumnoForm extends Component {

    render () {
        const { handleSubmit } = this.props
        return (
            <form
                onSubmit={this.props.handleSubmit}
            >
                <input name="alumnoname"  type="text"/>
                <button type="submit">Clear</button>
            </form>
        )
    }
}

AlumnoForm = reduxForm({
    form: 'alumnoform'
})(AlumnoForm)
export default AlumnoForm