import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react'
import DanteEditor from  'Dante2'
import * as Constants from '../../../Constants/Constants';
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../Actions/ClasesActions'
import { history } from '../../../Helpers/History'
import EstudiantesNotas from './EstudiantesNotas';
import * as Messages from  '../../../Helpers/Messages'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';

import NewUploader from './newUploader'

export const ClaseAdd = ()=>{
    const dispatch = useDispatch()
    //const materia = useParams()
    const [title, setTitle] = useState("");
    const [evaluacion, setEvaluacion] = useState(null);
    const [imagen, setImagen] = useState(null)


    //Hay varias cosas comentadas que faltan por terminar

    const materia = useSelector(state => state.materiaReducer.materia)
    const claseCreated = false//useSelector(state => state.claseReducer.claseCreated)
    const clase = useSelector(state => state.claseReducer.clase)
    const error = useSelector(state => state.claseReducer.error)
    const loading = useSelector(state=> state.claseReducer.isProcessing)

    useEffect(() => {
        if(claseCreated){
            history.push(`panel/${materia.slug}/clase/${clase.id}`)
        } 
         
        
    }, [claseCreated])

    const handleSubmit = (e) => { 
        e.preventDefault();
        const newClase = { title, evaluacion , imagen}
        console.log(newClase)
        //dispatch(actions.createClaseAction(newClase))
    }
    
    const onChangeTitle = (e) => {
        const title = e.target.value;
        setTitle(title);
    };
    const toMateria = () => {
        window.history.back();
    }
    
    const handleEvaluacion = (e) =>{
        e.preventDefault()
        e.target.id === 'true' ? setEvaluacion(true) : setEvaluacion(false)
    }
    
    const onChangeImagen = (imagen) => {
        
        setImagen(imagen)
    }
    
    return (
        
        <div className="container mgntop main-clase clase-add">
            { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
            { loading ? <LoadingMessage /> : null }
            <div className="materia-title">Historia del Diseño I</div>
            <div className="especialidad">Diseño Grafico</div>
            <div className="back-arrow" onClick={toMateria}></div>
            <div className="container bordered">
                <div className="col-sm-5 centered p-5 content">
                    
                    <form onSubmit={handleSubmit} >
                        <div className="form-group">
                        <label htmlFor="title" className="especialidad">Coloca aqui el titulo de la lección</label>
                        <input 
                        type="text"
                        name="title" 
                        id="title" 
                        className="" 
                        placeholder="Titulo"
                        required="required"
                        onChange={onChangeTitle}
                        /*value={this.state.username}*/
                            />
                        </div>
                        
                        
                        <p className="especialidad">¿Esta lección tendrá evaluación?</p>
                        <div className="btn-group">
                            <button id="true" className={evaluacion === true && 'selected'} onClick={handleEvaluacion}>Si, seguro</button>
                            <button id="false" className={evaluacion === false && 'selected'} onClick={handleEvaluacion}>Mejor la siguiente</button>
                        </div>

                        <p className="especialidad">Agregue aquí la portada de la clase</p>

                        < NewUploader handleOnSave={onChangeImagen} />
                        {/* < PicEditor userID={0} handleOnSave={onChangeImagen} className="clase-add-editor" /> */}
                        
                        <button onClick={handleSubmit}>Crear nueva clase</button>

                    </form>
                
                    
                </div>
            </div>
            
        </div>
        
    )
}

export default ClaseAdd