import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faInbox, faBars } from '@fortawesome/free-solid-svg-icons'
import {useSelector, useDispatch} from 'react-redux'
import * as actions from '../../../Actions/UserActions'
import * as Constants from '../../../Constants/Constants'

const TopNavBar = () => {


    const dispatch = useDispatch()

    const userID                            = localStorage.getItem(Constants.TOKEN_SESSION_USER_ID)


    useEffect(() => {
        const getUserInfo = () => dispatch(actions.getUserDataAction(userID))
        getUserInfo()
    }, [])
    
    const signOut = (e) => {
      e.preventDefault()    
    }

    const userData      = useSelector(state => state.userReducer.userData)
    const loading       =  useSelector (state => state.userReducer.isProcessing )
    const error         =  useSelector (state => state.userReducer.isError )
    console.log(userData)
  
    return(
        
    <nav className="navbar navbar-expand-md navbar-dark lighten-1">
      <a className="navbar-brand" href="#"></a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-555"
        aria-controls="navbarSupportedContent-555" aria-expanded="false" aria-label="Toggle navigation">
          <FontAwesomeIcon icon={faBars} size="1x"/>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent-555">
        
        <div className="ml-auto search-bar-area">
          <input disabled type="text" placeholder="buscar..." className="form-control" />
        </div>

        <ul className="navbar-nav ml-auto menu-top-icons">              
          <li>
            <Link className='disabled' to="#">
              <div className="action"> <span>mensajes</span> 
                <FontAwesomeIcon icon={faInbox} size="1x"/>
              </div>
            </Link>
          </li>
          <li>
            <Link className='disabled' to="#">
              <div className="action"><span>notificaciones</span>
                <FontAwesomeIcon icon={faBell} size="1x"/>
              </div>
            </Link>
          </li>              
        </ul>


        <ul className="navbar-nav ml-auto nav-flex-icons">
          
          <li className="nav-item avatar dropdown">
            
            <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-55" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
                <div className="username-text">{userData.nombre ? userData.nombre : "Usuario"}</div>
              {/*<img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg" className="rounded-circle z-depth-0"
                alt="avatar image" />*/}
                <img src="/img/default_userpic.gif" className="rounded-circle z-depth-0"
                alt="avatar image" />
              
            </a>
            <div className="dropdown-menu dropdown-menu-lg-right dropdown-secondary"
              aria-labelledby="navbarDropdownMenuLink-55">
              <a className="dropdown-item" href="/logout">Cerrar Sesión</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    )
    
}

export default TopNavBar;