import userReducer from './UserReducer'
import estudianteReducer from './EstudianteReducer'
import inscripcionReducer from './InscripcionReducer'
import materiaReducer from './MateriaReducer'
import claseReducer from './ClaseReducer'
import notaReducer from './NotasReducer'
import mensajesReducer from './MensajeReducer'
import profesorReducer from './ProfesorReducer'
import periodoReducer from './PeriodoReducer'
import alumnoReducer from '../Pages/Dashboard/Alumno/AlumnoReducer'
import { combineReducers } from 'redux'
import { reducer as reduxForm } from 'redux-form'

const rootReducer = combineReducers({
    userReducer,
    estudianteReducer,
    inscripcionReducer,
    alumnoReducer,
    materiaReducer,
    claseReducer,
    profesorReducer,
    notaReducer,
    mensajesReducer,
    periodoReducer,
    form: reduxForm
});

export default rootReducer;