import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../Actions/PeriodoActions'
import * as Constants from '../../../Constants/Constants'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
import * as Messages from  '../../../Helpers/Messages'

export const HorariosCarrera = ({handleSelection}) => {
    const carreras      = useSelector(state => state.periodoReducer.carreras)
    const carrerasSem   = useSelector(state => state.periodoReducer.materiasSemestre);

    const dispatch = useDispatch()


    // FUNCTIONS
    const onClick = (e) => {
        e.preventDefault()
        const carrera           = {id:e.target.id, titulo:e.target.dataset.titulo}
        const carreraSelected   = carrerasSem.find(car => car.carreraID === e.target.id);
        const semestres         = carreraSelected.semestres;
        const semestresIDs      = semestres.map(sem => sem.semestre )
        dispatch(actions.setCarreraHorario(carrera))
        dispatch(actions.setSemestresHorario(semestresIDs))

        // handleSelection('semestre')
        handleSelection('horarios')
    }



    return (
        <div className="horarios">
        {carreras.map(car => 
            <button className="carrera" onClick={onClick} key={car.id} 
            data-titulo={car.titulo} 
            id={car.id}>{car.titulo}</button>
            )}
        
        </div>
    )

}

export default HorariosCarrera