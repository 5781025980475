import React, { useState } from 'react';
import { EstudiantePreview } from '../Estudiantes/EstudiantePreview';
import { ProfesoresList } from './ProfesoresAdminList';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Draggable from 'react-draggable'
import InscripcionAdd from '../Inscripciones/InscripcionAdd';

export const ProfesoresAdminPage = (props) => {
    
    const [selectedProfesor, sendPreview] = useState({
       
    }); 

    const getSelectedProfesor = (profesor) => {
        sendPreview(profesor)
    }


    return (
        <>  
            <div className="container nopadding ">
                <div className="row center-content">
                    <div className="col-12 col-md-8 h-100">
                        <h4 className="text-left">Profesores</h4>
                        <ProfesoresList handleClickPreview={ getSelectedProfesor } />                    
                        <hr/>
                    
                    </div>
                    <div className="col-12 col-md-4">
                        <EstudiantePreview estudiante={ selectedProfesor } admin={true} />
                    </div>

                </div>
            </div>
        </>
    )
}