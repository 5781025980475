import * as actions from '../Actions/ActionTypes'


const initialState = {
    currentUser: {
        username    : "",
        password    : ""  
    },
    isProcessing: false,
    isError: false,
    isProcessingPassword: false,
    isErrorPassword: false,
    errorMessage: "",
    loggedIn:  null,
    userData:{},
    dataSaved:null,
    picSaved: false,
    savingPic:false,
    errorSavingPic: false,
    savingData:false,
    successfullyChanged: null,
    isTokenAuthenticated:null,
    isEmailSent:null

 };
  
export default function userReducer(state = initialState, action) {
    switch (action.type) {

        case actions.LOGIN_USER_INIT:
            return {...state,
                    currentUser: action.payload,
                    isProcessing: true,
                    isError: false
                }
        case actions.LOGIN_USER_SUCCESS: 
            return {...state,
                    isProcessing: false,
                    isError: false,
                    loggedIn: true,
            }
        case actions.LOGIN_USER_ERROR:           
            return {...state,
                isProcessing: false,
                isError: true,
                errorMessage: action.payload,
                loggedIn: false,
            }

            
        case actions.USER_GET_DATA_INIT:
            return {...state,
                    isProcessing: action.payload,
                    isError: false
                }
        case actions.USER_GET_DATA_SUCCESS: 
            return {...state,
                    isProcessing: false,
                    isError: false,
                    userData:action.payload
            }
        case actions.USER_GET_DATA_ERROR:           
            return {...state,
                isProcessing: false,
                isError: action.payload,
            }
        case actions.USER_SAVE_DATA_INIT:
            return {...state,
                    savingData: action.payload,
                    isError: false
                }
        case actions.USER_SAVE_DATA_SUCCESS: 
            return {...state,
                    savingData: false,
                    isError: false,
                    dataSaved:true
            }
        case actions.USER_SAVE_DATA_ERROR:           
            return {...state,
                savingData: false,
                isError: action.payload,
            }

        case actions.USER_SAVE_PIC_INIT:
            return {...state,
                savingPic: action.payload,
                errorSavingPic: false
            }
        case actions.USER_SAVE_PIC_SUCCESS: 
            return {...state,
                savingPic: false,
                errorSavingPic: false,
                picSaved:true
            }
        case actions.USER_SAVE_PIC_ERROR:           
            return {...state,
                savingPic: false,
                errorSavingPic: action.payload,
            }

        case actions.USER_SAVE_DATA_TO_FALSE: 
            return {...state,
                isProcessing: false,
                isError: false,
                dataSaved:action.payload
            }
        case actions.USER_SAVE_PIC_TO_FALSE: 
            return {...state,
                isProcessing: false,
                isError: false,
                dataSaved:action.payload
            }
        case actions.USER_RECUPERACION_EMAIL_INIT:
            return {...state,
                    isProcessing: action.payload,
                    isError: false
                }
        case actions.USER_RECUPERACION_EMAIL_SUCCESS: 
            return {...state,
                    isProcessing: false,
                    isError: false,
                    isEmailSent:action.payload
            }
        case actions.USER_RECUPERACION_EMAIL_ERROR:           
            return {...state,
                isProcessing: false,
                isError: action.payload,
            }

        case actions.AUTHENTICATE_TOKEN_INIT:
            return {...state,
                    isProcessing: action.payload,
                    isError: false
                }
        case actions.AUTHENTICATE_TOKEN_SUCCESS: 
            return {...state,
                    isProcessing: false,
                    isError: false,
                    isTokenAuthenticated:action.payload,
                    loggedIn: true,
            }
        case actions.AUTHENTICATE_TOKEN_ERROR:           
            return {...state,
                isProcessing: false,
                isError: action.payload,
            }

        case actions.USER_CHANGE_PASSWORD_INIT:
            return {...state,
                    isProcessingPassword: action.payload,
                    isErrorPassword: false
                }
        case actions.USER_CHANGE_PASSWORD_SUCCESS: 
            return {...state,
                    isProcessingPassword: false,
                    isErrorPassword: false,
                    successfullyChanged:action.payload
            }
        case actions.USER_CHANGE_PASSWORD_ERROR:           
            return {...state,
                isProcessingPassword: false,
                isErrorPassword: action.payload,
            }
        case actions.USER_CHANGE_SAVED_TO_FALSE:           
            return {...state,
                isErrorPassword: false,
                isProcessingPassword: false,
                successfullyChanged: action.payload,
            }
            
        default:
            return state;
    }
}