import React from 'react';
import { Link } from 'react-router-dom';

export const BackButton = ( destiny ) => {

    console.log("destiny", destiny)

    return (
        <Link
            to={destiny.to}>
            <img 
                src="/icons/back-arrow.svg"
                className="img-fluid"
            /> 
        </Link>
    )
}