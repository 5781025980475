import * as actions from './ActionTypes';
import * as Constants from '../Constants/Constants';
import axios from 'axios';
import clientAxios from './clientAxios';



//GET y POST mensajes entre usuario y chat seleccionado
export const getMensajesActions = ( senderID, receiverID ) => {
    
    return async(dispatch) => {
        dispatch(mensajesByUsers())
        try {
            const response = await clientAxios.get(`chat/sender/${senderID}/receiver/${receiverID}/qty/10`)
            console.log(response)
            dispatch(mensajesByUsersSuccess(response.data.data.results))
        } catch(error) {
            console.log(error)
            dispatch(mensajesByUsersError())
        }
    }
}

const mensajesByUsers = () => ({
    type: actions.MENSAJES_GET_BY_USERS_INIT, 
    payload: true
})

const mensajesByUsersSuccess = (results) => ({
    type: actions.MENSAJES_GET_BY_USERS_SUCCESS,
    payload: results
})

const mensajesByUsersError = () => ({
    type: actions.MENSAJES_GET_BY_USERS_ERROR,
    payload: true
})

export const sendMensajeAction = (senderID, receiverID, content) => {
    return async(dispatch) => {
        dispatch(sendMensaje())
        try{
            const response = await clientAxios.post(`/chat/sender/${senderID}/receiver/${receiverID}`, content)
            console.log(response)
            dispatch(sendMensajeSuccess())
        } catch(error){
            console.log(error)
            dispatch(sendMensajeError())
        }

    }
}

const sendMensaje = () => ({
    type:actions.MENSAJE_SEND_INIT,
    payload: true
})

const sendMensajeSuccess = () => ({
    type:actions.MENSAJE_SEND_SUCCESS,
    payload: true
})
const sendMensajeError = () => ({
    type:actions.MENSAJE_SEND_ERROR,
    payload: true
})





//GET info detallada del ActiveChat
export const getActiveChatAction = (userID) => {
    return async (dispatch) => {
        dispatch(getActiveChat())
        try {
            const response = await clientAxios.get(`/chat/user/${userID}`)
            console.log(response)
            dispatch(getActiveChatSuccess(response.data.data.results))
        } catch(error){
            console.log(error)
            dispatch(getActiveChatError())
        }
    }
}

const getActiveChat = () => ({
    type:actions.MENSAJES_ACTIVE_CHAT_INIT,
    payload: true
})

const getActiveChatSuccess = (results) => ({
    type:actions.MENSAJES_ACTIVE_CHAT_SUCCESS,
    payload: results
})
const getActiveChatError = () => ({
    type:actions.MENSAJES_ACTIVE_CHAT_ERROR,
    payload: true
})




//GET lista de profesores
export const getProfesoresChatAction = () => {
    return async (dispatch) => {
        dispatch(getProfesoresChat())
        try {
            const response = await clientAxios.get('/chat/profesores')
            console.log(response)
            
            dispatch(getProfesoresChatSuccess(response.data.data.results))
        } catch(error){
            console.log(error)
            dispatch(getProfesoresChatError())
        }
    }
}

const getProfesoresChat = () => ({
    type:actions.MENSAJES_GET_PROFESORES_INIT,
    payload: true
})

const getProfesoresChatSuccess = (results) => ({
    type:actions.MENSAJES_GET_PROFESORES_SUCCESS,
    payload: results
})
const getProfesoresChatError = () => ({
    type:actions.MENSAJES_GET_PROFESORES_ERROR,
    payload: true
})




//GET lista de estudiantes
export const getEstudiantesChatAction = () => {
    return async (dispatch) => {
        dispatch(getEstudiantesChat())
        try {
            const response = await clientAxios.get('/chat/estudiantes')
            console.log(response)
            
            dispatch(getEstudiantesChatSuccess(response.data.data.results))
        } catch(error){
            console.log(error)
            dispatch(getEstudiantesChatError())
        }
    }
}

const getEstudiantesChat = () => ({
    type:actions.MENSAJES_GET_ESTUDIANTES_INIT,
    payload: true
})

const getEstudiantesChatSuccess = (results) => ({
    type:actions.MENSAJES_GET_ESTUDIANTES_SUCCESS,
    payload: results
})
const getEstudiantesChatError = () => ({
    type:actions.MENSAJES_GET_ESTUDIANTES_ERROR,
    payload: true
})





//FakeActions: para pruebas

export const sendMensajeFakeAction = (content) => ({
    type:"SEND_FAKE_MENSAJE",
    payload: content
})


export const getActiveChatFakeAction = (activeChat) => ({
   type: "GET_FAKE_CHAT",
   payload: activeChat
})