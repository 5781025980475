import React from 'react';
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import FadeIn from 'react-fade-in'

export const ClaseBoxEstudiante = ({ clase, parent }) => {

    const dispatch  = useDispatch();
    const history   = useHistory();

    const gotoEditRedirect = (clase) => {
       /* dispatch( actions.editClasesAction (clase))
        history.push(`/panel/clase/${clase.id}`)*/
    }

    return (
        <div className="">
            
                <div className="clase">
                    <Link 
                    to={`/panel/${parent.slug}/clase/${clase.id}`}>
                        <div className="row align-items-center">
                            <div className="col-sm-6 nopadding">
                                {clase.imagen ? (
                                <img 
                                    src={clase.imagen}
                                    className="img-fluid"
                                />
                                ) : (
                                <img 
                                src="/img/default.jpg"
                                className="img-fluid"
                                />)
                                }  
                            </div>
                            <div className="col-sm-6 text-left text-col">
                                <div className="title">{clase.title}</div>
                                <div className="date">Creado el {clase.creation_date}</div>     
                            </div>
                        </div>              
                    </Link>                                    
                </div>
            <br></br>
        </div>
    )
}

