import React from 'react'

const InfoProfesor = () =>{
    return(
        <>
        Prueba de contenido de profesor
        </>
    )
}

export default InfoProfesor