import * as actions from './ActionTypes';
import * as Constants from '../Constants/Constants';
// import axios from 'axios';
//import { useHistory } from "react-router-dom";
// import { browserHistory } from 'react-router'
// import { history } from '../Helpers/History';
import clientAxios from './clientAxios';


const LoginUserAction = (dataFields) => {
    return { 
        type: actions.LOGIN_USER_INIT,
        payload: dataFields
    }
}
const LoginUserError = (response) => ({
    type: actions.LOGIN_USER_ERROR,
    payload: response
})
const LoginUserSuccess = (/*response*/) => ({
    type: actions.LOGIN_USER_SUCCESS,
   // payload: response
})

export function logout(userInfo) {
    return { 
        type: actions.LOGOUT_USER_ACTION, 
        userInfo 
    };
}

export const checkIfLogged = () => {
    return (dispatch) => {
        const token = localStorage.getItem(Constants.TOKEN_SESSION);
        if (token != null){
            dispatch(LoginUserSuccess())
        }
    }
}

export const initLoginAction = ( dataFields ) => {
    return async (dispatch) => {
        dispatch(LoginUserAction(dataFields))
        try {
            const result = await clientAxios.post(
                Constants.ApiURL+"/login",
                {username: dataFields.username,
                password: dataFields.password}
            )
            .then((response) => {
                if (response.data.status === "success"){
                    console.log(response)
                    dispatch(LoginUserSuccess(response.data))
                    localStorage.setItem(Constants.TOKEN_SESSION, response.data.data.results.token) 
                    localStorage.setItem(Constants.TOKEN_SESSION_USER_ID, response.data.data.results.id)
                    localStorage.setItem(Constants.TOKEN_SESSION_ROLE, response.data.data.results.role)
                } else {
                    dispatch(LoginUserError(response.data.data))
                }
              })
             
        } catch (error) {
            console.log("error", error)
            //dispatch(LoginUserError(error))
        }
    }
}

export const getUserDataAction = (userID) => {
    return async (dispatch) => {
        dispatch(getUserData())
        try{
            const response = await clientAxios.get(`/user/${userID}`)
            console.log(response)
            dispatch(getUserDataSuccess(response.data.data.results[0]))
        }
        catch(error){
            console.log(error)
            dispatch(getUserDataError())
        }
    }
}
const getUserData = () =>({
    type:actions.USER_GET_DATA_INIT,
    payload:true
})
const getUserDataSuccess = (results) =>({
    type:actions.USER_GET_DATA_SUCCESS,
    payload:results
})
const getUserDataError = () =>({
    type:actions.USER_GET_DATA_ERROR,
    payload:true
})

export const saveUserDataAction = (userID, userData) => {
    return async (dispatch) => {
        dispatch(userDataEdit())        
        try {
             const response = await clientAxios.put(`/user/${userID}`, userData)
             console.log(response)
             dispatch(userDataEditSuccess(response.data.data.results))
        } catch (error) {
             dispatch(userDataEditError())
        }
     
     }
}

const userDataEdit = () => ({
    type: actions.USER_SAVE_DATA_INIT, 
    payload: true
})

const userDataEditSuccess = (response) => ({
    type: actions.USER_SAVE_DATA_SUCCESS,
    payload: response
})

const userDataEditError = () => ({
    type: actions.USER_SAVE_DATA_ERROR,
    payload: true
})

export const saveUserPicAction = (userID, userData) => {
    return async (dispatch) => {
        dispatch(userPicEdit())        
        try {
             const response = await clientAxios.put(`/user/${userID}`, userData)
             console.log(response)
             dispatch(userPicEditSuccess(response.data.data.results))
        } catch (error) {
             dispatch(userPicEditError())
        }
     
     }
}

const userPicEdit = () => ({
    type: actions.USER_SAVE_PIC_INIT, 
    payload: true
})

const userPicEditSuccess = (response) => ({
    type: actions.USER_SAVE_PIC_SUCCESS,
    payload: response
})

const userPicEditError = () => ({
    type: actions.USER_SAVE_PIC_ERROR,
    payload: true
})


export const saveUserDataToFalse = () => ({
    type: actions.USER_SAVE_DATA_TO_FALSE, 
    payload: false
})

export const changeUserPasswordAction = (userID, userData) => {
    return async (dispatch) => {
        dispatch(changeUserPassword())        
        try {
            const response = await clientAxios.put(`/user/seguridad/${userID}`, userData)
            console.log(response)
            dispatch(changeUserPasswordSuccess())
            
        } catch (error) {
             dispatch(changeUserPasswordError())
        }
     
     }
}

const changeUserPassword = () => ({
    type: actions.USER_CHANGE_PASSWORD_INIT, 
    payload: true
})

const changeUserPasswordSuccess = (/*response*/) => ({
    type: actions.USER_CHANGE_PASSWORD_SUCCESS,
    payload: true
})

const changeUserPasswordError = () => ({
    type: actions.USER_CHANGE_PASSWORD_ERROR,
    payload: true
})

export const changeSavedToFalse = () => ({
    type: actions.USER_CHANGE_SAVED_TO_FALSE,
    payload: false
})

export const recuperacionEmailAction = (email) =>{
    return async (dispatch) => {
        dispatch(recuperacionEmail())        
        try {
             const response = await clientAxios.put(`/user/recuperacion/${email}`)
             
             dispatch(recuperacionEmailSuccess(response.data.data.results))
        } catch (error) {
             dispatch(recuperacionEmailError())
        }
     
     }
}
const recuperacionEmail = () => ({
    type: actions.USER_RECUPERACION_EMAIL_INIT, 
    payload: true
})

const recuperacionEmailSuccess = (response) => ({
    type: actions.USER_RECUPERACION_EMAIL_SUCCESS,
    payload: response
})

const recuperacionEmailError = () => ({
    type: actions.USER_RECUPERACION_EMAIL_ERROR,
    payload: true
})


export const authenticateTokenAction = (token) => {
    return async (dispatch) => {
        dispatch(authenticateToken())
        try {
            const response = await clientAxios.get(`/athenticate/token/${token}`)
            dispatch(authenticateTokenSuccess(response.data.data.results))
        } catch (error) {
            console.log("Error", error)
            dispatch(authenticateTokenError())
        }
    }
}

const authenticateToken = () => ({
    type: actions.AUTHENTICATE_TOKEN_INIT, 
    payload: true
})

const authenticateTokenSuccess = (response) => ({
    type: actions.AUTHENTICATE_TOKEN_SUCCESS,
    payload: response
})

const authenticateTokenError = () => ({
    type: actions.AUTHENTICATE_TOKEN_ERROR,
    payload: true
})
