import {useSelector, useDispatch} from 'react-redux'
import React, { useEffect } from 'react';
import * as actions from '../../../Actions/EstudiantesActions'
import * as elems from '../../../AFakeData/myfakedata'

import * as Messages from  '../../../Helpers/Messages'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';

export const EstudiantesInfo = ({ materiaID }) => {
    const dispatch = useDispatch();
    const quantity = 5
    useEffect(()=>{
        const cargarEstudiantes = () => dispatch(actions.getEstudiantesByMateria(materiaID, quantity))
        cargarEstudiantes();
    }, [])

    const estudiantesListArray = useSelector (state => state.estudianteReducer.estudiantes)
    console.log(estudiantesListArray)

    

    
    const estudiantesTotal = useSelector(state => state.estudianteReducer.estudiantesTotal)
    
    const error = useSelector(state => state.estudianteReducer.error)
    const loading = useSelector(state => state.estudianteReducer.isProcessing)

    //De momento este componente solomuestra error debido a que la API no esta mandando una respuesta a esta ruta aun
    return (
        <div className="row-attendees">
            
            { error  ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
            { loading ? <LoadingMessage /> : null }
            {estudiantesListArray.length === 0 && loading !== true ? <div className="materia-message">{Messages.ESTUDIANTES_LIST__EMPTY}</div> : (
                estudiantesListArray.map( estudiante => (        
                    <img 
                    key={estudiante.id}
                    src={estudiante.imagen !== null ? estudiante.imagen : "/img/default_userpic.gif"} 
                    className="rounded-circle userpic-list"
                    alt="avatar" 
                    />
                ))) 
            }
            
            {estudiantesTotal > 0 ? <small className="materia-message">{`${estudiantesTotal} estudiantes`}</small>  : null}
                                   
        </div>
    )
}



export default EstudiantesInfo