import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';


export const DocUploader = ({handleOnSave, docTipo}) => {

    const docTypes = docTipo === 'fotos' ? 'image/jpeg, image/png' : '.pdf'  
    const numOfFiles = docTipo === 'fotos' ? 2 : 1  
    // const {acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        maxFiles:numOfFiles, 
        accept: docTypes,
        minSize:0,
        maxSize:5242880
    });

    const [imgSrc, setImgSrc] = useState(null)
    const [docName, setDocName] = useState(docTipo[0].toUpperCase() + docTipo.slice(1, docTipo.length))

    
    const files = acceptedFiles.map(file => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setImgSrc(reader.result)
            handleOnSave(reader.result, docTipo)
        });
        reader.onerror = (error) => console.log('Error: ', error)
        reader.readAsDataURL(file);
        return (
            <li key={file.path}>
                {file.path} - {file.size} bytes
            </li>
        )}
    );

    return (
        <section className="container">
            <div {...getRootProps({className: 'custom-uploader'})}>
                {files.length > 0 ? (
                    <>
                    <h4>{docName}</h4>
                    <ul>{files}</ul>
                    </>
                ) : (
                    <>
                    <input {...getInputProps()} />
                    <div className="upload-icon"></div>
                    <p className="text">{docName} - Arrastra o has click para seleccionar</p>
                    </>
                )}
                

            </div>
        {/* <aside>
            <h4>Files</h4>
            <ul>{files}</ul>
        </aside> */}
        </section>
    );
}

export default DocUploader