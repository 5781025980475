import * as actions from './ActionTypes';
import * as Constants from '../Constants/Constants';
import clientAxios from './clientAxios';
import axios from 'axios';

// export function crearInscripcion(data){

//     return () => {
//         console.log('1- Crear desde ACTIONjs')
//     }
//     /*return async (dispatch) => {
//         console.log('1- Crear desde ACTIONjs')
//         dispatch(addInscripcion());
//         //temp, borrar esto
//         const inscripcion = ""
//         try{
//             //axios Insert (hacia Api)
//             await clientAxios.post('/inscripcion', inscripcion);
//             //si todo ok, actualizar State (hacia State)
//             dispatch(addInscripcionSucess(inscripcion))
//         } catch (error){
//             //si hay error, cambiar state
//             dispatch(addInscripcionError(true))
//         }
//     }*/
// }


export function addInscripcionAction(data){
    return async (dispatch) => {
        dispatch (addInscripcion())        
        try {
            const response = await clientAxios.post(`/inscripcion`, data) 
            
            console.log(response)
            dispatch(addInscripcionSuccess(response.data.data.results))
        } catch (error) {
            console.log(error)
            dispatch(addInscripcionError())
        }
    }
}

const addInscripcion = () => ({
    type: actions.INSCRIPCION_ADD_ESTUDIANTE_INIT,
    payload: true
})

const addInscripcionSuccess = (result) => ({
    type: actions.INSCRIPCION_ADD_ESTUDIANTE_SUCCESS,
    payload: result
})

const addInscripcionError = () => ({
    type: actions.INSCRIPCION_ADD_ESTUDIANTE_ERROR,
    payload: true
})


// export const addInscripcion = (inscripcion) => ({
//     type: types.INSCRIPCION_ADD,
//     payload: true
// })
// // si la inscripcion se guarda
// const addInscripcionSucess = (inscripcion) => ({
//     type: types.INSCRIPCION_ADD_SUCCESS,
//     payload: inscripcion
// })
// // si hubo un error
// const addInscripcionError = (estado) => ({
//     type: types.INSCRIPCION_ADD_ERROR,
//     payload: estado
// })

// const getInscripion = (id) => ({
//     type: types.INSCRIPCION_GET,
//     payload: id
// })


export function getInscripcionesAllAction(){
    return async (dispatch) => {
        dispatch (getInscripcionesAll())        
        try {
            const response = await clientAxios.get(`/inscripciones`) 
            
            console.log(response)
            dispatch(getInscripcionesAllSuccess(response.data.data.results))
        } catch (error) {
            dispatch(getInscripcionesAllError())
        }
    }
}

const getInscripcionesAll = () => ({
    type: actions.INSCRIPCIONES_GET_ALL_INIT,
    payload: true
})

const getInscripcionesAllSuccess = (result) => ({
    type: actions.INSCRIPCIONES_GET_ALL_SUCCESS,
    payload: result
})

const getInscripcionesAllError = () => ({
    type: actions.INSCRIPCIONES_GET_ALL_ERROR,
    payload: true
})





export function saveDocumentsByStudentAction( estudianteID, data){
    console.log(data)
    return async (dispatch) => {
        dispatch (saveDocumentsByStudent())        
        try {
            const response = await clientAxios.post(`/inscripcion/${estudianteID}/documentos`, data) 
            
            console.log(response)
            dispatch(saveDocumentsByStudentSuccess(response.data.data.results))
        } catch (error) {
            console.log(error)
            dispatch(saveDocumentsByStudentError())
        }
    }
}

const saveDocumentsByStudent = () => ({
    type: actions.INSCRIPCIONES_DOCUMENTOS_SAVE_INIT,
    payload: true
})

const saveDocumentsByStudentSuccess = (result) => ({
    type: actions.INSCRIPCIONES_DOCUMENTOS_SAVE_SUCCESS,
    payload: true
})

const saveDocumentsByStudentError = () => ({
    type: actions.INSCRIPCIONES_DOCUMENTOS_SAVE_ERROR,
    payload: true
})

export const savedDocsToFalseActions = () => ({
    type: actions.SAVED_DOCS_TO_FALSE,
    payload:false
})

export function getDocumentsByStudentAction(estudianteID){
    return async (dispatch) => {
        dispatch (getDocumentsByStudent())        
        try {
            const response = await clientAxios.get(`/inscripcion/${estudianteID}/documentos`) 
            
            console.log(response.data.data.results)
            dispatch(getDocumentsByStudentSuccess(response.data.data.results))
        } catch (error) {
            dispatch(getDocumentsByStudentError())
        }
    }
}

const getDocumentsByStudent = () => ({
    type: actions.INSCRIPCIONES_DOCUMENTOS_GET_ALL_INIT,
    payload: true
})

const getDocumentsByStudentSuccess = (result) => ({
    type: actions.INSCRIPCIONES_DOCUMENTOS_GET_ALL_SUCCESS,
    payload: result
})

const getDocumentsByStudentError = () => ({
    type: actions.INSCRIPCIONES_DOCUMENTOS_GET_ALL_ERROR,
    payload: true
})