
export const ApiURL = "https://api.disegnovalencia.com/api";//"https://api2.disegnovalencia.com/api";//"https://test.disegnovalencia.com/html/api";//


export const Link_inscripciones__add    = "/panel/inscripcion/add/"
export const Link_inscripciones__edit   = "/panel/inscripcion/edit/"
export const Link_inscripciones__detail = "/panel/inscripcion/"
export const Link_inscripciones__main   = "/panel/inscripciones/"


export const Link_estudiantes__edit     = "/panel/estudiante/edit/"
export const Link_estudiantes__detail   = "/panel/estudiante/"



export const TOKEN_SESSION              =   "idv_token"
export const TOKEN_SESSION_USER         =   "idv_user"
export const TOKEN_SESSION_ROLE         =   "idv_role"
export const TOKEN_SESSION_FULLNAME     =   "idv_fullname"
export const TOKEN_SESSION_USER_ID      =   "idv_user_id"
