import React from 'react';
import { Link } from 'react-router-dom';
import * as HelperFunctions from '../../../Helpers/Functions';
import { MateriaInfo } from './MateriaInfo';

export const MateriaBox = ({ materia }) => {

    const slug = HelperFunctions.slugify(materia.especialidad);
    
    return (

        <div className={`col-sm-4 materia-box ${slug}`}>
            <Link to={{ 
                pathname: `/panel/materia/${materia.id}`,
                linkProps:{ materia:materia }
                }}j
                >
                <div className="card">
                    <MateriaInfo 
                        materia={materia}
                    />
                </div>
                <br></br>
            </Link>
        </div>
    )           
}

export default MateriaBox