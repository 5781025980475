import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import * as actions from '../../../Actions/InscripcionesActions'
import DocUploader from './DocumentosUploader'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
import * as Messages from  '../../../Helpers/Messages'
import {ApiURL} from '../../../Constants/Constants'
                            

export const Documentos = ({userID, isEditable}) => {

    useEffect(()=>{
        const getCurrentDocuments = () => dispatch(actions.getDocumentsByStudentAction(userID))
        getCurrentDocuments()
    }, [])


    
    const dispatch              = useDispatch()
    const savedDocs             = useSelector(state => state.inscripcionReducer.savedDocs)
    const loading               = useSelector(state => state.inscripcionReducer.isProcessingDocs)
    const error                 = useSelector(state => state.inscripcionReducer.errorDocs)

    

    useEffect(()=>{
        if(savedDocs === true){
            const getCurrentDocuments = () => dispatch(actions.getDocumentsByStudentAction(userID))
            const savedDocsToFalse    = () => dispatch(actions.savedDocsToFalseActions())
            getCurrentDocuments()
            setTimeout(()=>{
                savedDocsToFalse()
            },3000)
        }
        
    }, [savedDocs])

    const docs = useSelector(state => state.inscripcionReducer.docs)
    const [docsObj, setDocsObj] = useState({})

    // useEffect(()=>{
    //     setDocsObj(docs)
    // }, [docs])


    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(docsObj)
        dispatch(actions.saveDocumentsByStudentAction(userID, docsObj))
    }

    // const handleChange = (e) =>{
    //     console.log(e.target.files[0])
    //     const selectedFile = e.target.files[0];
    //     const nameInput = e.target.id

    //     getBase64(selectedFile, nameInput)
        
    // }

    const handleChange = (file, doc) =>{
        const tempObj = docsObj
        tempObj[doc] = file
        setDocsObj(tempObj)
        
    }

   
     
    return (
        <div className="documentos">
            <h1>Documentos </h1>
            <div className="container">
                { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
                { loading ? <LoadingMessage /> : null }
                <form>

                    <div className="row">
                        {docs.map((doc, index)=>(

                            <div className="col-6 docs-box" key={index}>
                                
                                {doc.url ? (
                                    <a href={`${ApiURL}/${doc.url}`} target="_blank" className="">{doc.titulo}</a>
                                ) : (
                                    <>
                                        <DocUploader handleOnSave={handleChange} docTipo={doc.tipo} />
                                        {/* <label htmlFor={doc}>{doc}</label>
                                        <input type="file" onChange={handleChange} id={doc} name={doc} /> */}
                                        {/* <label htmlFor={doc}>
                                            <input type="file" onChange={handleChange} id={doc} name={doc} />
                                            <span>{doc}</span>
                                        </label> */}
                                    </>
                                )}
                               
                            </div>
                        ))}
                        
                    </div>
                    { !loading ?
                        <button className="documentos-btn" onClick={handleSubmit}>Guardar documentos</button> :
                        null
                    }
                    {/* <div className="row">
                        <div className="col d-flex flex-column border border-primary">
                            Titulo
                            <input type="file" onChange={handleChange} id="titulo" name="titulo" />

                        </div>
                        <div className="col d-flex flex-column border border-primary">
                            Notas certificadas
                            <input type="file" onChange={handleChange} id="notas" name="notas" />
                        </div>
                        <div className="col d-flex flex-column border border-primary">
                            Cédula
                            <input type="file" onChange={handleChange} id="cedula" name="cedula" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex flex-column border border-primary">
                            Partida de nacimiento
                            <input type="file" onChange={handleChange} id="partida" name="partida" />
                        </div>
                        <div className="col d-flex flex-column border border-primary">
                            Fotos
                            <input type="file" onChange={handleChange} id="fotos" name="fotos" />
                        </div>
                    </div> */}
                    
                </form>
                
            </div>
        </div>
    )
}