import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import { Inscripciones } from './Inscripciones';
import { InscripcionDetail } from './InscripcionDetail';
import InscripcionAdd from '../Inscripciones/InscripcionAdd';
import { EstudiantePreview } from '../Estudiantes/EstudiantePreview';
import {getCarrerasAllAction} from '../../../Actions/PeriodoActions'

export const InscripcionesPage = (props) => {
    
    const dispatch = useDispatch()
    useEffect(()=>{
        const getCarrerasList = () => dispatch(getCarrerasAllAction())
        getCarrerasList()
    },[])

    const especialidades = useSelector(state => state.periodoReducer.carreras)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedEstudiante, sendPreview] = useState({}); 
 
    const getSelectedEstudiante = (estudiante) => {
         sendPreview(estudiante)
    }
    return (
        <>
        <Modal 
            show={show} 
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            // centered
            dialogClassName="inscripcion-add"
            
            backdropClassName="no-bg"
            >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <InscripcionAdd especialidades={especialidades} />
            </Modal.Body>
            
        </Modal>
        <div className="container nopadding inscripciones">
            <div className="col-12 col-md-8 text-right"> 
                <a href="#" className="btn btn-action" onClick={handleShow}>
                    <img className="action-icon" 
                        alt="Editar" 
                        src="../../icons/add.svg" 
                    />
                    Inscribir Alumno
                </a>
            </div>
            <div className="row center-content">
                
                <div className="col-12 col-md-8 h-100">
                    <Inscripciones handleClickPreview={getSelectedEstudiante} />
                    <hr/>
                </div>
                <div className="col-12 col-md-4">
                    {/* <InscripcionDetail handleClickPreview={getSelectedEstudiante} /> */}
                    <EstudiantePreview estudiante={ selectedEstudiante } inscripcion={true} admin={true}/>

                </div>

            </div>
        </div>
        </>
    )
}