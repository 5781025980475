import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../../Actions/PeriodoActions'
import * as Constants from '../../../Constants/Constants'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
import * as Messages from  '../../../Helpers/Messages'
import SemestreBox from './SemestreBox'

export const MateriasByCarrera = ({carreraID, carreraNombre}) => {
    
    const dispatch = useDispatch()

    useEffect(() => {
        if(carreraID){
            
            isInState(carreraID)
        }
    }, [carreraID])


    const [currentCarrera, setCurrentCarrera]               = useState(null)
    const materiasSemestreArray                             = useSelector(state => state.periodoReducer.materiasSemestre)
    const loading                                           = useSelector(state => state.periodoReducer.isProcessing)
    const error                                             = useSelector(state => state.periodoReducer.error)
    
    
    useEffect(()=>{
        if(materiasSemestreArray.length > 0){
            const car = materiasSemestreArray.find(car => car.carreraID ===  carreraID);
            
            if(car !== undefined){
                const newSemestres = car.semestres.map(sem => {
                    const materias = sem.materias.map(mat => {
                        if(mat.id > 0){
                            return mat
                        }
                    })
                    return {semestre:sem.semestre, materias: materias}
                    
                })
            
                setCurrentCarrera(currentCarrera => currentCarrera = car )
            }
        }
    },[materiasSemestreArray, carreraID])

    const handleSubmit = (e) => {
        e.preventDefault()
        const newCarrerasArray = materiasSemestreArray.map(carrera =>{
            const newSemestres = carrera.semestres.map(sem => {
                const materias = sem.materias.map(mat => {
                    if(mat.selected === true) return mat
                })
                return {semestre:sem.semestre, materias: materias}
                
            })
            return {carreraID: carrera.carreraID, semestres: newSemestres}
        })
        //dispatch(actions.savingSelectedCarrerasSemestreAction(newCarrerasArray))
    }

    const isInState = (carID) => {
        const car = materiasSemestreArray.find(car => car.carreraID === carID);
        if(car === undefined){
            const loadMateriasBySemestre = () => dispatch(actions.getMateriasBySemestreAction(carID))
            loadMateriasBySemestre()
        }
    }


    const addMateria = (e) => {
        e.preventDefault()
        const newSemestres = currentCarrera.semestres.map(sem => {
            const materias = sem.materias.map(mat => {
                if(mat.id === e.target.id){
                    mat['selected'] ? mat['selected'] = false : mat['selected'] = true
                }
                return mat
            })
            return {semestre:sem.semestre, materias: materias}
            
        })
        const newCarrera = {
            carreraID: currentCarrera.carreraID,
            semestres:newSemestres
        }
        const newCarrerasArray = materiasSemestreArray.filter(car => car.carreraID !== newCarrera.carreraID)
        newCarrerasArray.push(newCarrera)
        dispatch(actions.setNewCarrerasSemestres(newCarrerasArray))
    }



    const addAllCarreras = (e) => {
        
        e.preventDefault()
        const newSemestres = currentCarrera.semestres.map(sem => {
            if(sem.semestre == e.target.dataset.semestre){
                const materias = sem.materias.map(mat => {
                    mat['selected'] ? mat['selected'] = false : mat['selected'] = true
                    return mat
                })
                return {semestre:sem.semestre, materias: materias}

            }
            return sem
            
        })
        const newCarrera = {
            carreraID: currentCarrera.carreraID,
            semestres:newSemestres
        }
        const newCarrerasArray = materiasSemestreArray.filter(car => car.carreraID !== newCarrera.carreraID)
        newCarrerasArray.push(newCarrera)
        dispatch(actions.setAllMaterias(newCarrerasArray))
    }


    
    return(
        <div className="container materias-by-semestre">
            { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
            { loading ? <LoadingMessage /> : null }
            {/* { currentCarrera ? currentCarrera.semestres.map((sem, index) => <SemestreBox key={index} carreraNombre={carreraNombre} semestre={sem} /> ) : null} */}
            
            { currentCarrera ? currentCarrera.semestres.map((sem, index) =>  (
            <div className="semestre-box"  key={sem.semestre}>
            <h2>{currentCarrera.titulo}. {sem.semestre}th semestre</h2>
                <button  id="all" data-semestre={sem.semestre} className="materias-btn" onClick={addAllCarreras} >Seleccionar todas</button>
                <div>{sem.materias.map(matSem => <button key={matSem.id} onClick={addMateria} id={matSem.id}  
                                className={`materias-btn ${matSem.selected ? 'selected' : ''}`} 
                                >{matSem.nombre}</button>)}</div>
            
            
            </div>
            // <div>
            //     <h3>{sem.semestre}</h3>
            //     <ul>
            //         {sem.materias.map(mat => <li>{mat.nombre}</li>)}
            //     </ul>
            // </div>
            )
            ) : null}
            
            {/* <button onClick={handleSubmit} className="materias-btn">Guardar materias</button> */}
        </div>
    )
}

export default MateriasByCarrera