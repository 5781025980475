export const currentUser = {
    id:1,
    nombre:'Cristiam1',
    apellido:'Castellanos',
    thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'
}
export const estudiantesInfo ={
    estudiantes:[
        {
            id:1,
            nombre:'Cristiam1',
            apellido:'Castellanos',
            entidad:'estudiante',
            promedio:'18.8',
            semestre:'4to semestre',
            thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'
        },
        {
            id:2,
            nombre:'Cristiam2',
            apellido:'Castellanos',
            entidad:'estudiante',
            promedio:'18.8',
            semestre:'4to semestre',
            thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'
        },
        {
            id:3,
            nombre:'Cristiam3',
            apellido:'Castellanos',
            entidad:'estudiante',
            promedio:'18.8',
            semestre:'4to semestre',
            thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'
        },
        {
            id:4,
            nombre:'Cristiam4',
            apellido:'Castellanos',
            entidad:'estudiante',
            promedio:'18.8',
            semestre:'4to semestre',
            thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'
        },
        {
            id:5,
            nombre:'Cristiam5',
            apellido:'Castellanos',
            entidad:'estudiante',
            promedio:'18.8',
            semestre:'4to semestre',
            thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'
        },

    ],
    estudiantesTotal:22,
}
export const profesores =[
        {
            id:1,
            nombre:'profesor1',
            apellido:'Castellanos',
            entidad:'profesor',
            materias:['primera materia', 'segunda materia'],
            thumbnail:'https://images.unsplash.com/photo-1606830079880-e90bd08e9a98?ixid=MXwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
        },
        {
            id:2,
            nombre:'profesor2',
            apellido:'Castellanos',
            entidad:'profesor',
            materias:['primera materia', 'segunda materia'],
            thumbnail:'https://images.unsplash.com/photo-1606830079880-e90bd08e9a98?ixid=MXwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
        },
        {
            id:3,
            nombre:'profesor3',
            apellido:'Castellanos',
            entidad:'profesor',
            materias:['primera materia', 'segunda materia'],
            thumbnail:'https://images.unsplash.com/photo-1606830079880-e90bd08e9a98?ixid=MXwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
        },
        {
            id:4,
            nombre:'profesor4',
            apellido:'Castellanos',
            entidad:'profesor',
            materias:['primera materia', 'segunda materia'],
            thumbnail:'https://images.unsplash.com/photo-1606830079880-e90bd08e9a98?ixid=MXwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
        },
        {
            id:5,
            nombre:'profesor5',
            apellido:'Castellanos',
            entidad:'profesor',
            materias:['primera materia', 'segunda materia'],
            thumbnail:'https://images.unsplash.com/photo-1606830079880-e90bd08e9a98?ixid=MXwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
        },

]


export const notas = [
        {
            claseID:1,
            estudianteID:1,
            value:17

        },
        {
            claseID:2,
            estudianteID:1,
            value:17

        },
        {
            claseID:4,
            estudianteID:1,
            value:17

        },
        {
            claseID:6,
            estudianteID:1,
            value:17

        },
        {
            claseID:1,
            estudianteID:2,
            value:17

        },
        {
            claseID:2,
            estudianteID:2,
            value:17

        },
        {
            claseID:4,
            estudianteID:2,
            value:17

        },
        {
            claseID:6,
            estudianteID:2,
            value:17

        },
        
        {
            claseID:1,
            estudianteID:3,
            value:17

        },
        {
            claseID:2,
            estudianteID:3,
            value:17

        },
        {
            claseID:4,
            estudianteID:3,
            value:17

        },
        {
            claseID:6,
            estudianteID:3,
            value:17

        },
        
        {
            claseID:1,
            estudianteID:4,
            value:17

        },
        {
            claseID:2,
            estudianteID:4,
            value:17

        },
        {
            claseID:4,
            estudianteID:4,
            value:17

        },
        {
            claseID:6,
            estudianteID:4,
            value:17

        },
        

        {
            claseID:1,
            estudianteID:5,
            value:17

        },
        {
            claseID:2,
            estudianteID:5,
            value:17

        },
        {
            claseID:4,
            estudianteID:5,
            value:17

        },
        {
            claseID:6,
            estudianteID:5,
            value:17

        },
]

export const notasByClase = [
    {
        estudianteID:1,
        value:17

    },
    {
        estudianteID:2,
        value:17

    },
    {
        estudianteID:3,
        value:17

    },
    {
        estudianteID:4,
        value:17

    },
    {
        estudianteID:5,
        value:17

    },
]

export const clases = [
    {
        id:1,
        evaluada:true,
        nombre:'Historia I',
        creation_date:'2020-11-16 20:43:40',
        slug:'prueba1'
    },
    {
        id:2,
        evaluada:true,
        nombre:'Histoaria II',
        creation_date:'2020-11-16 20:43:40',
        slug:'prueba2'
    },
    {
        id:3,
        evaluada:true,
        nombre:'Historia del color',
        creation_date:'2020-11-16 20:43:40',
        slug:'prueba3'
    },
    {
        id:4,
        evaluada:true,
        nombre:'Historia del color II',
        creation_date:'2020-11-16 20:43:40',
        slug:'prueba4'
    },
    {
        id:5,
        evaluada:true,
        nombre:'Historia del color otro',
        creation_date:'2020-11-16 20:43:40',
        slug:'prueba5'
    },
    {
        id:6,
        evaluada:true,
        nombre:'Historia IV',
        creation_date:'2020-11-16 20:43:40',
        slug:'prueba6'
    },

]


export const horario =  {Horario: [ { dia: "Lunes", entrada: "7:00", salida: "9:00" }, { dia: "Miércoles", entrada: "7:00", salida: "9:00" }, { dia: "Viernes", entrada: "7:00", salida: "9:00" } ] }



export const estudiantesNotas = {
    1:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    2:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    3:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    4:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    5:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    6:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    7:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    8:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    9:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    10:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    11:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    12:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    13:{
        nombre:'Cristiam',
        apellido:'Castellanos',
        thumbnail:'https://images.unsplash.com/photo-1593642532781-03e79bf5bec2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        notas:[
            {
                claseID:1,
                estudianteID:1,
                value:17

            },
            {
                claseID:2,
                value:17

            },
            {
                claseID:4,
                value:17

            },
            {
                claseID:6,
                value:17

            },
            
        ]
    },
    
}


