import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Constants from '../../../Constants/Constants'
import { LinksAdmin } from '../Nav/LinksAdmin'
import { LinksProfesor } from './LinksProfesor';
import { LinksEstudiante } from './LinksEstudiante';
import * as roles from '../../../Constants/Roles'
const timeoutLength = 200;
const role = localStorage.getItem(Constants.TOKEN_SESSION_ROLE)

class NavBar extends Component {

    handleBoxToggle(){
        var element = document.getElementById('sidebar');
        element.classList.toggle("inactive");
    }
    leaveButton = () => {
        //Wait before close, avoid flickering
        setTimeout(() => {
            var element = document.getElementById('sidebar');
            element.classList.toggle("inactive");
        }, timeoutLength);
      }
      
      loadMenuByRole(role) {
        switch(role) {
            case roles.admin_role:
                return <LinksAdmin />
            case roles.profesor_role:
                return <LinksProfesor />
            default:
                return <LinksEstudiante />
        }
      }
      
      

      render () {
        
        return(            
            
            <nav id="sidebar" 
                onMouseEnter={this.handleBoxToggle} 
                onMouseLeave={this.leaveButton}           
                className="inactive col-1 position-absolute vh-100">
                <div>
                    <Link to="/panel">
                        <div className="navbar-logo"></div>                     
                    </Link>                   
                </div>
                {this.loadMenuByRole(role)}
            </nav>
        )
    }
}


export default NavBar;