import * as actions from './ActionTypes';
import * as Constants from '../Constants/Constants';
import axios from 'axios';
import clientAxios from './clientAxios';
import Swal from 'sweetalert2';
import * as elems from '../AFakeData/myfakedata'

//
// GET
// P1 ESTUDIANTES 
export function getEstudiantesAllAction(periodoID){
    return async (dispatch) => {
        dispatch (getEstudiantes())        
        try {
            const response = await clientAxios.get(`/estudiantes/${periodoID}`) 
            // const response = await clientAxios.get(`/estudiantes`) 
           // console.log(response.data.results.clases)
            console.log(response)
            dispatch(getEstudiantesSuccess(response.data.data.results))
        } catch (error) {
            dispatch(getEstudiantesError())
        }
    }
}
//P1
const getEstudiantes = () => ({
    type: actions.ESTUDIANTES_GET_ALL_INIT,
    payload: { isProcessing: true} 
})
//P-1A
const getEstudiantesSuccess = (estudiantes) => ({
    type: actions.ESTUDIANTES_GET_ALL_SUCCESS,
    payload: estudiantes
})
//P-1B
const getEstudiantesError = () => ({
    type: actions.ESTUDIANTES_GET_ALL_ERROR,
    payload: true
})

export function getEstudiantesByMateria( materiaID, quantity){

    return async (dispatch) => {
        dispatch (getEstudiantesInfo())  
        try {
            const response = await clientAxios.get(`/estudiantes/${quantity}/materia/${materiaID}`) //`estudiantes/?materia=${materiaID}&qty=${quantity}`)
            console.log(quantity, materiaID)
            console.log(response)
            dispatch(getEstudiantesByMateriaSuccess(response.data.data.results)) //API real: response.data.results
        } catch (error) {
            console.log(error)
            dispatch(getEstudiantesByMateriaError())
        }
    }
}

const getEstudiantesInfo = () => ({
    type: actions.ESTUDIANTES_GET_BY_MATERIA_INIT,
    payload: true
})
//P-1A
const getEstudiantesByMateriaSuccess = (results) => ({
    type: actions.ESTUDIANTES_GET_BY_MATERIA_SUCCESS,
    payload: results
})
//P-1B
const getEstudiantesByMateriaError = () => ({
    type: actions.ESTUDIANTES_GET_BY_MATERIA_ERROR,
    payload: true
})