import * as actions from '../Actions/ActionTypes'

const initialState = {
    isProcessing:false,
    profesores:[],
    error:false
};

export default function profesorReducer(state = initialState, action) {
    switch (action.type) {
        case actions.PROFESORES_GET_ALL_INIT:
            return {...state,                
                isProcessing: action.payload 
            }
        case actions.PROFESORES_GET_ALL_SUCCESS:
            return {...state,                
                isProcessing: false,
                profesores: action.payload
            }
        case actions.PROFESORES_GET_ALL_ERROR:
            return {...state,                
                isProcessing: false,
                error: action.payload
            }
        default:
        return state;
    }
}