import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Constants from '../../../Constants/Constants'
import * as Messages from  '../../../Helpers/Messages'
import SingleChatBar from './SingleChatBar'
import * as elems from '../../../AFakeData/myfakedata'
import * as actions from '../../../Actions/MensajesActions'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';

export const BandejaMensajes = () => {
    const [showEstudiantesList, setList] = useState(true)

    const dispatch = useDispatch()
    useEffect(()=>{
        const getEstudiantesList = () => dispatch(actions.getEstudiantesChatAction())
        getEstudiantesList()
    }, [])


    const estudiantesList = useSelector(state => state.mensajesReducer.estudiantes)
    const profesoresList= useSelector(state => state.mensajesReducer.profesores)
    const loading = useSelector(state => state.mensajesReducer.isProcessingLists)
    const error = useSelector(state => state.mensajesReducer.errorLists)

    let chatList = showEstudiantesList ? estudiantesList : profesoresList
    
    const ContentChatList = (e)=>{
        
        const role = e.target.id

        if(role === 'alumnos'){
            setList(true)
        }else{
            setList(false)
            if(profesoresList.length === 0){
                dispatch(actions.getProfesoresChatAction())
            }
            
        }
        
    }

    
    return(
        <div className="bandeja">
            <div className="d-flex">
                <div onClick={ContentChatList} id="alumnos" className={!showEstudiantesList ? "ptñ non-selected" : "ptñ"}>Alumnos</div>
                <div onClick={ContentChatList} id="profesores"  className={showEstudiantesList ? "ptñ non-selected" : "ptñ"}>Profesores</div>
            </div>
            <div className="lista-wrapper">
                {loading ? <LoadingMessage /> : null}
                { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
                {loading || error ? null : ( 
                <div className="lista">
                    {chatList.map(user => user ? < SingleChatBar key={user.id_user} user={user} /> : null )}
                </div>
                )}
            </div>
        </div>
    )
}

export default BandejaMensajes