import React, { Component } from 'react';
import { AreaLogin } from './AreaLogin'
import AreaTrivia from './AreaTrivia'
import { connect } from 'react-redux';
import * as actions from '../../Actions/UserActions'
import { history } from '../../Helpers/History'

class LoginPage extends Component {
    
    constructor (props) {
        super(props);        
    }


    render(){
        const { match } = this.props
        return(
            <div className="container-fluid">
		        <div className="row vh-100">
                    <div className="area-login col-12 col-md-5 h-100">
                        <div className="row justify-content-md-center">                           
                         
                          <AreaLogin
                                //handleSuccessfulAuth = {this.handleSuccessfulAuth}                                 
                            />
                        </div>
                    </div>
                    <div className="area-trivia col-12 col-md-7 h-100 d-none d-md-block d-sm-none">
                        <div className="row  align-items-center justify-content-center h-100">
                            {/*<AreaTrivia />*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default LoginPage