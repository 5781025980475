import * as types from '../../../Actions/ActionTypes'
import { userActions } from '../../../Actions/InscripcionesActions';


const initialState = {
    alumno: {
        nameAlumno: ''
    },
    addingAlumno: false  
 };

const alumnoReducer = (state = initialState, action) => {
    switch(action.type){
        case 'START_ALUMNO_ADD':         
           return Object.assign({}, state, {
               alumno: action.payload,
               addingAlumno: true,
           })          
        case 'ALUMNO_ADD': 
            return Object.assign({}, state, {
                addingAlumno: false
            })           
           /* return {...state, 
                userdata: action.payload,
                isSubmitting: false
            }*/
        default: return state
    }
}

export default alumnoReducer