import * as actions from './ActionTypes';
import clientAxios from './clientAxios';
import Swal from 'sweetalert2';
//
// GET
// CLASES GET BY MATERIA
export function getClasesByMateriaAction (materiaID){
    console.log(materiaID)
    return async (dispatch) => {
        dispatch (getClases(materiaID))        
        try {
            
            const response = await clientAxios.get(`materia/${materiaID}/clases`) //materia/:id/clases
            console.log(response)
          // const returnedArray = Array.from(response.data.results.materias) //response.data.results
            dispatch(getClasesSuccess(response.data.data.results)) //API real : "response.data.results"
            //Puedo enviarlo entre parentesis o referenciar en el object el nombre del obj del response
        } catch (error) {
            console.log(error)
            dispatch(getClasesError())
        }
    }
}
//P1
const getClases = (materiaID) => ({
    type: actions.CLASES_GET_BY_MATERIA_INIT,
    payload: {id: materiaID, isProcessing: true, deleteItem: null} 
})
//P-1A
const getClasesSuccess = (results) => ({
    type: actions.CLASES_GET_BY_MATERIA_SUCCESS,
    payload: results
})
//P-1B
const getClasesError = () => ({
    type: actions.CLASES_GET_BY_MATERIA_ERROR,
    payload: true
})

//
// GET 1 CLASE BY ID
//
//P-1

export function getClaseByIDAction (claseID){
    return async (dispatch) => {
        dispatch (getClase(claseID))
        
        try {
            const response = await clientAxios.get(`/clase/${claseID}`) //clientAxios.get(`/clases/${claseID}`)
            console.log(response)
            dispatch(getClaseSuccess(response.data.data.results))
               //Puedo enviarlo entre parentesis o referenciar en el object el nombre del obj del response
        } catch (error) {
            dispatch(getClaseError())
        }
    }
}
//P1
const getClase = (claseID) => ({
    type: actions.CLASE_GET_BY_ID_INIT,
    payload: {id: claseID, isProcessing: true, deleteItem: null} 
})
//P-1A
const getClaseSuccess = (result) => ({
    type: actions.CLASE_GET_BY_ID_SUCCESS,
    payload: result
})
//P-1B
const getClaseError = () => ({
    type: actions.CLASE_GET_BY_ID_ERROR,
    payload: true
})





//
// DELETE
//
//P-1
export function deleteClasesAction(id) {
    return async (dispatch) => {
        dispatch( getClaseDelete(id) )
       // console.log(id)
        try {
            await clientAxios.get(`/clase/${id}`) ////clientAxios.delete(`/clase/${id}`)
            dispatch(deleteClaseSuccess())
            //if Delete sucess Show Alert
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
        } catch (error) {
            console.log(error)
            dispatch(deleteClaseError())
        }
    }
}
//P2
const getClaseDelete = ( id ) => ({
    type: actions.CLASE_DELETE_INIT,//CLASES_GET_ELEMENT_INIT
    payload: id
})

//P3-A
const deleteClaseSuccess = () => ({
    type: actions.CLASE_DELETE_SUCCESS,
    payload: false
})
//P3-B
const deleteClaseError = () => ({
    type: actions.CLASE_DELETE_ERROR,
    payload: true
})




//
// EDIT
//
//P-1
export function editClasesAction(clase) {
    return async (dispatch) => {
        dispatch( getClaseEdit(clase) )
        //No lleva try catch porque obtiene el objeto de la 1era consulta en el state, no de la base de datos,
        //aunque seria necesario? y si otra persona lo edita y sobres escribe mientras?
       // console.log(id)
       /* try {
            await clientAxios.get(`/clase/${id}`) ////clientAxios.delete(`/clase/${id}`)
            dispatch(deleteClaseSuccess())
            //if Delete sucess Show Alert
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
        } catch (error) {
            console.log(error)
            dispatch(deleteClaseError())
        }*/
    }
}

//Actual Edit
export function editClaseAction(clase) {
    return async (dispatch) => {
       dispatch(getClaseEdit(/*clase*/))        
       try {
            const resultado = await clientAxios.put(`/clase/${clase.id}`, clase)
            //console.log(resultado)
            dispatch(editClaseSuccess(clase))
       } catch (error) {
            dispatch(getClaseEditError())
       }
    
    }
}
//P2
const getClaseEdit = ( /*clase */) => ({
    type: actions.CLASE_EDIT_INIT, 
    //payload: clase
})
//P3-A
const editClaseSuccess = (clase) => ({
    type: actions.CLASE_EDIT_SUCCESS,
    payload: clase
})
/*


//P3-B
const deleteClaseError = () => ({
    type: actions.CLASES_DELETE_ERROR,
    payload: true
})*/
const getClaseEditError = () => ({
    type: actions.CLASE_EDIT_ERROR,
    payload: true
})

//create clase
export function createClaseAction(clase) {
    return async (dispatch) => {
       dispatch(createClaseInit())        
       try {
           
            const response = await clientAxios.post(`/clase`, clase) //hay que definir el url de este llamado
            
            dispatch(createClaseSuccess(response))
       } catch (error) {
            dispatch(createClaseError())
       }
    
    }
}

const createClaseInit = () => ({
    type: actions.CLASE_ADD_INIT, 
    payload: true
})
//P3-A
const createClaseSuccess = (clase) => ({
    type: actions.CLASE_ADD_SUCCESS,
    payload: clase
})

const createClaseError = () => ({
    type: actions.CLASE_ADD_ERROR,
    payload: true
})

export const addClassPicture = (userID, userData)=>{
    console.log('Ahora desde el action')
    console.log(userID, userData)
}