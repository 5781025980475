import React from 'react';

export const InfoBoxProfesor = ({ user }) => {
    return(
        
        <div className="ficha-info empty">
            
            
        </div>
        
    )
}

export default InfoBoxProfesor