import * as actions from './ActionTypes';

import clientAxios from './clientAxios';


export const getNotasByMateria = (materiaID) => {
    return async (dispatch) => {
        dispatch(notasByMateria())
        try {
            const response = await clientAxios.get(`/notas/materia/${materiaID}`)
            console.log(response)
            dispatch(notasByMateriaSuccess(response))
        }
        catch (error) {
            console.log(error)
            dispatch(notasByMateriaError())
        }
    }
}

const notasByMateria = () => ({
    type: actions.NOTAS_GET_BY_MATERIA_INIT, 
    payload: true
})

const notasByMateriaSuccess = (response) => ({
    type: actions.NOTAS_GET_BY_MATERIA_SUCCESS,
    payload: response
})

const notasByMateriaError = () => ({
    type: actions.NOTAS_GET_BY_MATERIA_ERROR,
    payload: true
})

export const getNotasByClase = (claseID) => {
    return async (dispatch) => {
        dispatch(notasByClase())
        try {
            const response = await clientAxios.get(`/notas/clase/${claseID}`)
            dispatch(notasByClaseSuccess(response.data.data))
        }
        catch (error) {
            console.log(error)
            dispatch(notasByClaseError())
        }
    }
}

const notasByClase = () => ({
    type: actions.NOTAS_GET_BY_CLASE_INIT, 
    payload: true
})

const notasByClaseSuccess = (response) => ({
    type: actions.NOTAS_GET_BY_CLASE_SUCCESS,
    payload: response
})

const notasByClaseError = () => ({
    type: actions.NOTAS_GET_BY_CLASE_ERROR,
    payload: true
})

export const saveNotas = (claseID, estudianteID, nota) => {
    return async (dispatch) => {
        dispatch(notasEdit())        
        try {
             const response = await clientAxios.put(`/notas/clase/${claseID}/estudiante/${estudianteID}/value/${nota}`)///notas/?clase=${claseID}&estudiante=${estudianteID}&value=${nota}
             //console.log(resultado)
             dispatch(notasEditSuccess(response))
        } catch (error) {
             dispatch(notasEditError())
        }
     
     }
}

const notasEdit = () => ({
    type: actions.NOTA_EDIT_ESTUDIANTE_INIT, 
    payload: true
})

const notasEditSuccess = (response) => ({
    type: actions.NOTA_EDIT_ESTUDIANTE_SUCCESS,
    payload: response
})

const notasEditError = () => ({
    type: actions.NOTA_EDIT_ESTUDIANTE_ERROR,
    payload: true
})