import * as actions from '../Actions/ActionTypes'

const initialState = {
    notas:{},
    notaSaved:false,
    isProcessing:false,
    error:false,
    saving: null,
}

export default function notaReducer(state = initialState, action) {
    switch (action.type) {
        case actions.NOTAS_GET_BY_MATERIA_INIT:
            return {...state,                
                isProcessing: action.payload //true
            }
        case actions.NOTAS_GET_BY_MATERIA_SUCCESS:
            return {...state,                
                isProcessing: false,
                notas: action.payload,
                error: false
            }
        
        case actions.NOTAS_GET_BY_MATERIA_ERROR:
            return {...state,
                isProcessing:false,
                error:action.payload
            }


        //Notas para una clase en especifico
        case actions.NOTAS_GET_BY_CLASE_INIT:
            return {...state,                
                isProcessing: action.payload //true
            }
        case actions.NOTAS_GET_BY_CLASE_SUCCESS:
            return {...state,                
                isProcessing: false,
                notas: action.payload,
                error: false
            }
        
        case actions.NOTAS_GET_BY_CLASE_ERROR:
            return {...state,
                isProcessing:false,
                error:action.payload
            }
        //Cambiar la nota de un estudiante en la vista de la clase
        case actions.NOTA_EDIT_ESTUDIANTE_INIT:
            return {...state,                
                isProcessing: action.payload //true
            }
        case actions.NOTA_EDIT_ESTUDIANTE_SUCCESS:
            return {...state,                
                isProcessing: false,
                notaSaved: action.payload,
                error: false
            }
        
        case actions.NOTA_EDIT_ESTUDIANTE_ERROR:
            return {...state,
                isProcessing:false,
                error:action.payload
            }
        default:
            return state
    }
}