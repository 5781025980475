import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Constants from '../../../Constants/Constants'
import * as Messages from  '../../../Helpers/Messages'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
import * as actions from '../../../Actions/MensajesActions'
import Mensaje from './Mensaje'
import EmptyChat from './EmptyChat'

export const MainChat = ({ activeChat, userID }) => {
    const dispatch = useDispatch()
    const successfullySent = useSelector(state => state.mensajesReducer.successfullySent)
    

    useEffect(()=>{
        if(activeChat.id !== undefined){
            const getMensajesByUser = ()=> dispatch(actions.getMensajesActions(userID, activeChat.id))
            getMensajesByUser()
        }
    }, [activeChat, successfullySent])
    
    const mensajes = useSelector(state => state.mensajesReducer.mensajes)
    
    const sendMensaje = (e) => {
        e.preventDefault()
        const content = {content: contenido}
        dispatch(actions.sendMensajeAction( userID ,activeChat.id, content))
        setContenido("")
    }

    const [contenido, setContenido] = useState('')
    const bottom = useRef()
    const errorLoading = useSelector(state => state.mensajesReducer.error)
    const loading = useSelector(state => state.mensajesReducer.isProcessing)
    const sending = useSelector(state => state.mensajesReducer.isSending)
    const errorSending = useSelector(state => state.mensajesReducer.errorSending)
    // //zona de prueba
    // const mensajes = useSelector(state => state.mensajesReducer.fakeMensajes)
    // console.log(mensajes)

    // const sendMensaje = (e) => {
    //     e.preventDefault()
    //     const content = {mensaje: contenido, senderID:userID, receiverID:user.id}
    //     dispatch(actions.sendMensajeFakeAction(content))

    //     setContenido('')
    //     bottom.current.scrollIntoView({behavior:"smooth"})
    // }
    //

    return(
        
        <div className="main-chat">
            { errorLoading ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR + " al cargar los mensajes"}</p> : null}
            { errorSending ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR + " al enviar el mensaje"}</p> : null}
            { loading ? <LoadingMessage /> : null }
            { !loading && activeChat.id === undefined ? <EmptyChat /> : null}
            {loading || activeChat.id === undefined ? null : ( 
            <div >
                <div className="mensajes-box">
                    {/* {mensajes.length < 1 ? <p className="empty-chat">{Messages.CHAT_EMPTY}</p> : null} */}
                    {mensajes.map(mensaje => <Mensaje key={mensaje.id} content={mensaje} userID={userID} />)}
                    <div ref={bottom}></div>
                </div>
                <form onSubmit={sendMensaje}>
                    <input type="text" placeholder="Escribe un mensaje" value={contenido} onChange={(e)=> setContenido(e.target.value)}></input>
                    <button className="send" type="submit"></button>
                </form>
            </div> )}
            
        </div>
    )
}

export default MainChat