import * as actions from '../Actions/ActionTypes'
import { userActions } from '../Actions/InscripcionesActions';

const initialState = {
    inscripciones: [],
    isProcesado:{},
    newEstudianteID:{},
    processingInscripcion: false,
    errorInscripcion: false,
    isProcessingInscripciones: false,
    errorInscripciones:false,
    docs:[],
    isProcessingDocs: false,
    savedDocs:false,
    errorDocs: false,
}

export default function inscripcionReducer(state = initialState, action){
    switch (action.type) {
        case actions.INSCRIPCIONES_GET_ALL_INIT:   //START_INSCRIPCION_ADD 
            return {...state, 
                isProcessingInscripciones: action.payload
            }
        case actions.INSCRIPCIONES_GET_ALL_SUCCESS:
            return {...state,
                isProcessingInscripciones: false,
                inscripciones: action.payload,
                errorInscripciones: false
            }   
        case actions.INSCRIPCIONES_GET_ALL_ERROR:
            return {...state,
                isProcessingInscripciones: false,
                errorInscripciones: action.payload
            }
        /*case 'INSCRIPCION_ADD':           
            return {...state, 
                userdata: action.payload,
                isSubmitting: false
            }*/
        case actions.INSCRIPCION_ADD_ESTUDIANTE_INIT:   //START_INSCRIPCION_ADD 
            return {...state, 
                processingInscripcion: action.payload
            }
        case actions.INSCRIPCION_ADD_ESTUDIANTE_SUCCESS:
            return {...state,
                processingInscripcion: false,
                newEstudianteID: action.payload,
                isProcesado:true,
                errorInscripcion: false
            }   
        case actions.INSCRIPCION_ADD_ESTUDIANTE_ERROR:
            return {...state,
                processingInscripcion: false,
                errorInscripcion: action.payload
            }
        case actions.INSCRIPCIONES_DOCUMENTOS_GET_ALL_INIT:   //START_INSCRIPCION_ADD 
            return {...state, 
                isProcessingDocs: action.payload
            }
        case actions.INSCRIPCIONES_DOCUMENTOS_GET_ALL_SUCCESS:
            return {...state,
                isProcessingDocs: false,
                docs: action.payload,
                errorDocs: false
            }   
        case actions.INSCRIPCIONES_DOCUMENTOS_GET_ALL_ERROR:
            return {...state,
                isProcessingDocs: false,
                errorDocs: action.payload
            }
        case actions.INSCRIPCIONES_DOCUMENTOS_SAVE_INIT:   
            return {...state, 
                isProcessingDocs: action.payload
            }
        case actions.SAVED_DOCS_TO_FALSE:   
            return {...state, 
                savedDocs: action.payload
            }
        case actions.INSCRIPCIONES_DOCUMENTOS_SAVE_SUCCESS:
            return {...state,
                isProcessingDocs: false,
                savedDocs: action.payload,
                errorDocs: false
            }   
        case actions.INSCRIPCIONES_DOCUMENTOS_SAVE_ERROR:
            return {...state,
                isProcessingDocs: false,
                errorDocs: action.payload
            }
        default: 
            return state
    }
}


            
          
            /*return {...state,
                    currentUser: action.payload,
                    loggedIn: true,
                    user: {...action.payload}
                }*/