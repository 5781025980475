import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';


export const InscripccionAddUploader = ({handleChange, setFileName}) => {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
    const [imgSrc, setImgSrc] = useState(null)
    const [imgName, setImgName] = useState(null)


    const file = acceptedFiles.map(file => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setImgSrc(reader.result)
            handleChange(reader.result)
        });
        reader.onerror = (error) => console.log('Error: ', error)
        reader.readAsDataURL(file);
        return <p>{file.path} <br/> {file.size} bytes</p> 
        
    });

    return (
        <>
            {imgSrc ? (
                <>
                <div className="inscripcion-add-img">
                    <img src={imgSrc} alt="Preview" />
                </div>
                <div className="file-name">{file}</div>
                </>
            ) : (
                <div {...getRootProps({className: 'inscripcion-add-uploader'})}>
                    <input {...getInputProps()} />
                </div>
            )}
        </>
    );
}

export default InscripccionAddUploader