import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Route, NavLink ,Link} from 'react-router-dom'
import { ProfilePage } from './ProfilePagePrueba'
import { Historial } from './HistorialPage'
import { Documentos } from './DocumentosPage'
import { Desempeño } from './DesempeñoPage'
import Progreso from '../Inscripciones/Progreso'
import PropTypes from 'prop-types';
import Seguridad from './SeguridadPage'
import * as actions from '../../../Actions/UserActions'
import * as roles from '../../../Constants/Roles'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
import * as Messages from  '../../../Helpers/Messages'
import * as HelperFunctions from '../../../Helpers/Functions';
import PicEditor from '../Profile/ProfileUploader'
import Modal from 'react-bootstrap/Modal';
import { useMouseWheel } from 'react-use'


export const FichaPerfil = (props) => {

    //From props
    const { match, userID, role, fromPerfil, isEditable, asAdmin, pestañas } = props


    //Hooks
    const dispatch                          = useDispatch()
    const [isEstudiante, setEstudiante]     = useState(null)
    const [show, setShow]                   = useState(false);


    const handleClose                       = () => setShow(false);
    const handleShow                        = () => setShow(true);

    useEffect(() => {
        const getUserInfo = () => dispatch(actions.getUserDataAction(userID))
        getUserInfo()
        ContentByRole(role)
    }, [])


    //State
    const userData      = useSelector(state => state.userReducer.userData)
    const loading       =  useSelector (state => state.userReducer.isProcessing )
    const error         =  useSelector (state => state.userReducer.isError )
    
    const picSaved          = useSelector(state => state.userReducer.picSaved)
    const savingPic         = useSelector(state => state.userReducer.savingPic)
    const errorSavingPic    = useSelector(state => state.userReducer.errorSavingPic)


    //Path variables:
    //Como el componente se reutiliza desde varias areas estas variables se encargan de que
    //las rutas sean compatibles.
    //SI se accede desde admin hay que reemplazar el ID del path, pero desde los demas no hay tal id
    const path = asAdmin ? match.path.replace(':id', userID) : match.path
    const slug = props.location.pathname



    //Functions

    const editProfilePic = (e) => {
        e.preventDefault()
        handleShow() 
    }
    

    function ContentByRole(role) {
        switch(role) {
            case roles.profesor_role:
                return setEstudiante(false)
            default:
                return setEstudiante(true)
        }
    }

    function handleOnSaveData(userID, userData){
        dispatch(actions.saveUserPicAction(userID, userData))
        handleClose()
    }
    function setPestaña(pestaña, index){

        //Agrega al path de esta area el slug de cada pestaña
        const pestañaSlug       = HelperFunctions.slugify(pestaña)
        const pestañaPath       = `${path}/${pestañaSlug}`
        let fadeout             = pestaña === 'Historial de Pagos' || pestaña === 'Desempeño Academico' ? true : false
        fadeout                 = !asAdmin && pestaña === 'Documentos' ? true : fadeout
        const isSelectedClass   = slug === pestañaPath ? 'selected' : ''

        return (
            < >
            { fadeout ? 
            // <div className="col-lg-3 col-md-6 col-sm-12 col-12 pestaña-navlink pestaña-navlink">
            //     <div 
            //     className="pestaña fadeout"  id={slug}>{pestaña}</div>
            // </div>

            <div className="col pestaña-navlink pestaña-navlink">
                <div 
                className="pestaña fadeout"  id={slug}>{pestaña}</div>
            </div>
                
             : 
                
                // <NavLink 
                // key={index}
                // to={fadeout ? '' : pestañaPath}
                // className="col-lg-3 col-md-6 col-sm-12 col-12 pestaña-navlink pestaña-navlink" >
                // <div 
                // className={`pestaña ${isSelectedClass}`}  id={slug}>{pestaña}</div> </NavLink>
                // }

                <NavLink 
                key={index}
                to={fadeout ? '' : pestañaPath}
                className="col pestaña-navlink pestaña-navlink" >
                <div 
                className={`pestaña ${isSelectedClass}`}  id={slug}>{pestaña}</div> </NavLink>
                }

            </>
            
            
        )
    }



    return(
        <>
            <Modal 
                show={show} 
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter-lg"
                centered
                size="lg" 
                //dialogClassName="inscripcion-add"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <PicEditor 
                        userID={userID} 
                        handleOnSave={handleOnSaveData} 
                        className="" />
                </Modal.Body>
                
            </Modal>
            <div className="profile-box">
                <div className="pestañas-group row">
                    

                    {/* Como es el default no hay que agreagar slug */}
                    {/* <NavLink 
                    to={`${path}`}
                    className="col-lg-3 col-md-6 col-sm-12 col-12 pestaña-navlink">
                        <div 
                        className={ slug === path ? "selected pestaña " : 'pestaña' } 
                        id="perfil">Datos Personales</div> 
                    </NavLink>   */}

                    <NavLink 
                    to={`${path}`}
                    className="col pestaña-navlink">
                        <div 
                        className={ slug === path ? "selected pestaña " : 'pestaña' } 
                        id="perfil">Datos Personales</div> 
                    </NavLink>  

                    {pestañas.map((ptñ, index) => setPestaña(ptñ, index))}

                   

                    
                </div>
                <div className="section-wrapper profile">
                    
                        <div className="row">
                            <div className="col-sm-12 col-lg-4 pic-wrapper">
                            { errorSavingPic ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
                            { loading ? <LoadingMessage /> : null }
                            { userData.nombre === undefined ? null : (
                                <div className="pic-wrapper-content">
                                    {savingPic && !errorSavingPic ?

                                    <div className="rounded-circle saving-pic">
                                        
                                            <LoadingMessage />
                                       
                                    </div>
                                    
                                    
                                    :

                                    <img
                                    src={userData.imagen !== null ? userData.imagen : "/img/default_userpic.gif"}
                                    alt="Imagen de usuario"
                                    className="rounded-circle" />
                                    

                                }
                                    
                                    <button onClick={editProfilePic}>Editar imagen de perfil</button>
                                    { isEstudiante && asAdmin ? 
                                    <div className="progreso-ficha">
                                        <p>PREOGRESO DE INSCRIPCION</p>
                                        <Progreso estudiante={userData} />
                                    </div> : null}
                                    <div className="name-box">
                                        <div className="fullname">
                                            <span>{userData.nombre.toLowerCase()} </span>
                                            <span>{userData.apellido.toLowerCase()}</span>
                                        </div>
                                        <div className="especialidad">{userData.especialidad}</div>
                                    </div>
                                    <div className="contact-box">
                                        <div className="email"><div className="icon"></div><div>{userData.email}</div></div>
                                        <div className="phone"><div className="icon"></div><div>(0241)-825.00.00</div></div>
                                        <div className="cellphone"><div className="icon"></div><div>(0241)-825.00.00</div></div>
                                    </div>
                                    {isEstudiante ? (<div>Contenido de estudiante</div>) : null}
                                </div>
                            )}
                            </div>
                            <div className="col-sm-12 col-lg-8  content">

                            
                                <Switch>
                                    { userData.nombre === undefined ? null : (
                                    <Route 
                                        exact path={`${path}`}
                                        render={(props) => <ProfilePage {...props} 
                                                            isEditable={isEditable} 
                                                            userData={userData}
                                                            closeModalPic={handleClose} 
                                                            userID={userID}
                                                            role={role}
                                                            isEstudiante={isEstudiante} /> }
                                    />)}


                                    <Route path={`${path}/seguridad`} 
                                    render={props => <Seguridad {...props} 
                                                        isEditable={isEditable} 
                                                        userID={userID} 
                                                        fromPerfil={fromPerfil} />} 
                                    />

                                    
                                    { asAdmin ? 
                                    <>
                                    <Route path={`${path}/historial-de-pagos`} 
                                    render={props =><Historial {...props} 
                                                        isEditable={isEditable} 
                                                        userData={userData} />} 
                                    
                                    />
                                    
                                    <Route path={`${path}/historial`} 
                                    render={props =><Historial {...props} 
                                                        isEditable={isEditable}
                                                        userData={userData} />} 
                                    />
                                    
                                    <Route path={`${path}/documentos`} 
                                    render={props =><Documentos {...props} 
                                                        isEditable={isEditable}
                                                        userID={userID} />} 
                                    /> 
                                    </>
                                    : null}
                                    
                                    <Route path={`${path}/desempeno-academico`} 
                                    render={props =><Desempeño {...props} 
                                                        isEditable={isEditable}
                                                        userData={userData} />} 
                                    />

                                </Switch>
                                
                            </div>
                        </div>        
                        
                </div>
            </div>
        </>      
    )
   
 }

FichaPerfil.propTypes = {
    match: PropTypes.any.isRequired
}

export default FichaPerfil