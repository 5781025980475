import React, { Fragment, Component, useState} from 'react';
import axios from 'axios';
import * as Constants from '../../../Constants/Constants';
import { Link } from 'react-router-dom';
import * as Messages from  '../../../Helpers/Messages'
import Progreso from '../Inscripciones/Progreso'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faLocation } from '@fortawesome/free-solid-svg-icons'

export const EstudiantePreview = ({estudiante, inscripcion, admin}) => {

    const pathname = `/panel/${inscripcion ? 'inscripcion': 'estudiante'}/`

    return (
        <>
        { !estudiante.nombre ? <div></div> :(
        <div className="snapshot">
           
              <div>
                <img 
                    src="/img/default_userpic.gif" 
                    className="rounded-circle userpic-profile"
                    alt={estudiante.nombre} 
                />
                {inscripcion ? <div className="progreso-wrapper"><Progreso estudiante={estudiante} /></div> : null}
                <h3 className="fullname">
                    <span className="capitalize">{estudiante.nombre.toLowerCase()}</span> 
                     <span className="capitalize"> {estudiante.apellido.toLowerCase()}</span>    
                </h3>
                <h4>{estudiante.especialidad}<br/>
                {estudiante.semestre} semestre</h4>

                {/* <p>
                    <img 
                    src="/icons/view.svg" 
                    className="profile-info" />
                    {estudiante.nacimiento}</p> */}
                
                    <table className="profile-info-box">
                        <tbody>
                            <tr>
                                <td><img 
                                src="/icons/email.svg" 
                                className="profile-info" /></td>
                                <td>{estudiante.email}</td>
                            </tr>
                            <tr>
                                <td><img 
                                src="/icons/phone.svg" 
                                className="profile-info" /></td>
                                <td>{estudiante.phone ? estudiante.phone : "(412) 555-1312"}</td>
                                
                            </tr>
                            <tr>
                                <td><img 
                                src="/icons/cellphone.svg" 
                                className="profile-info" /></td>
                                <td>{estudiante.cellphone? estudiante.cellphone : "(241) 555-1312"}</td>
                            </tr>
                        </tbody>
                    </table>
                    { admin ?
                    <Link 
                        to={{ 
                            pathname: `${pathname + estudiante.id}`,
                            estudiante: estudiante
                        }}>                   
                        <button className="btn btn-regular">Ver Ficha</button>
                    </Link> : null}
              </div>
        </div>
        )} 
        </>
    )
}