import React from 'react'
import { useState , useEffect} from 'react'
import EstudiantesInfo from '../Estudiantes/EstudiantesInfoMateria'



export const EstudiantesRow = ({clases, estudiante, notas}) => {

    const Celda = ({ id, notas }) =>{
        
        let value = ''
        notas.map(nota => (
            nota.claseID === id ? value = nota.value : null
        ))
        return(
            <td 
            id={id} 
            >{value}</td>
        )
    }
    
    return(
        
        <tr>
            <td>
                <div className="username-and-pic">
                    <img
                    className="rounded-circle userpic-list"
                    src={estudiante.imagen ? estudiante.imagen : '/img/default_userpic.gif'} 
                    alt={`Foto de perfil ${estudiante.nombre}`} 
                    />
                    
                    <strong>{estudiante.nombre} {estudiante.apellido}</strong>
                </div>
                
            </td>
            {clases.map(clase => (
                clase.evaluada ? (<Celda key={clase.id} id={clase.id} notas={notas} />) : null
            )
            )}
                        
        </tr>
    )
}

export default EstudiantesRow