import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Constants from '../../../Constants/Constants'
import * as Messages from  '../../../Helpers/Messages'
import * as actions from '../../../Actions/MensajesActions'

export const SingleChatBar = ( {user} ) => {


   
    const dispatch = useDispatch()

    const setActiveChat = () => {
        const setChat = () => dispatch(actions.getActiveChatAction(user.id_user)) //
        setChat()
    }


    // const setActiveChat = () => {
    //     const setChat = () => dispatch(actions.getActiveChatFakeAction(user))
    //     setChat()
    // }

    return(
        <div onClick={setActiveChat} className="chat-bar ">
            <img
            className="rounded-circle userpic-list"
            src="/img/default_userpic.gif"
            alt={`Foto de perfil ${user.nombre.toLowerCase()}`} 
            />
            <div className="name-box">
                <strong className="name">{user.nombre.toLowerCase()} {user.apellido.toLowerCase()}</strong>
            </div>
            
            
        </div>
    )
}

export default SingleChatBar