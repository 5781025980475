import React, { useState, useEffect } from 'react';
import { reduxForm, Field, getFormInitialValues } from 'redux-form';
import AddForm from './AddForm';
import * as types from '../../../Actions/ActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../Actions/InscripcionesActions'
import { history } from '../../../Helpers/History'
import { } from '../../../Actions/InscripcionesActions'
import InscripcionAddUploader from './InscripcionAddUploader'

export const InscripcionAdd = ({especialidades}) => {

    //Funcion personalizada que pasa al componente de ReduxForm
    //desde aqui se inicia la logica de axios
    const dispatch                    = useDispatch()
    const [submitting, setSubmitting] = useState(false)
    const [imagenData, setImagen]         = useState(null)
    const procesado                   = useSelector(state => state.inscripcionReducer.isProcesado)
    const newEstudiante             = useSelector(state => state.inscripcionReducer.newEstudianteID)
    const semestres = [1,2,3,4,5,6,7,8,9,10]

    useEffect(()=>{
        if(procesado && newEstudiante.id){
            history.push(`/panel/inscripcion/edit/${newEstudiante.id}`) 
        }

    }, [procesado])

    const handleSubmitInscripcion = (e) => {
        e.preventDefault()
        setSubmitting(true)
        const data = new FormData(e.target)
        const values = {
            nombre: data.get('nombreField'),
            apellido:data.get('apellidoField'),
            cedula: data.get('cedulaField'),
            especialidad: data.get('especialidadField'),
            semestre: data.get('semestreField'),
            imagen:imagenData
        }
        console.log(values)
        dispatch(actions.addInscripcionAction(values))
    }
    
    const handleChange = (file) =>{
        setImagen(file)
    }
    return (
        <div className="inscripcion-add-box">
            {/* <div className="inscripcion-add-uploader"></div> */}
            <div className="inscripcion-add-uploader-box">
                < InscripcionAddUploader handleChange={handleChange} />
            </div>
            {/* <div className="icon-wrapper">
                <div className="email"></div>
                <div className="phone"></div>
                <div className="cellphone"></div>
            </div> */}
            <div className="form-group">
                <AddForm 
                    submitting={submitting}
                    handleSubmit={handleSubmitInscripcion}
                    especialidades={especialidades}
                    semestres={semestres}
                ></AddForm>
            </div>
            
        </div>
    )
    
}





export default InscripcionAdd