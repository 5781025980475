import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Constants from '../../../Constants/Constants'
import * as Messages from  '../../../Helpers/Messages'

export const Mensaje = ({ content, userID }) => {
    const classMensaje = content.senderID === userID ? 'sent' : 'received'
    return(
        <div className={`mensaje ${classMensaje}`}>
            <p>{content.mensaje}</p>
        </div>
    )
}

export default Mensaje