import * as actions from '../Actions/ActionTypes'

const initialState = {
    clases: [],
    materia: {},
    clase: {},
    content: null,
    isProcessing: false,
    //claseCreated:false
    editItem: null,
    deleteItem: null,
    saving: null,
    error: false
};
  
export default function claseReducer(state = initialState, action) {
    switch (action.type) {
    //GET ALL
        case actions.CLASES_GET_BY_MATERIA_INIT:
            return {...state,                
                isProcessing: action.payload //true
            }
        case actions.CLASES_GET_BY_MATERIA_SUCCESS:
            return {...state,                
                isProcessing: false,
                clases: action.payload,
                error: false
            }
        case actions.CLASES_GET_BY_MATERIA_ERROR:
            return {...state,                
                isProcessing: false,
                error: action.payload
            }
    //GET 1
        case actions.CLASE_GET_BY_ID_INIT:
            return {...state,                
                isProcessing: action.payload //true
            }
        case actions.CLASE_GET_BY_ID_SUCCESS:
            return {...state,                
                isProcessing: false,
                clase: action.payload,
                content: action.payload[0].content                
            }
        case actions.CLASE_GET_BY_ID_ERROR:
            return {...state,                
                isProcessing: false,
                error: action.payload
            }

    //ADD
        case actions.CLASE_ADD_INIT:
            return {...state,
                isProcessing:action.payload
            }
        case actions.CLASE_ADD_SUCCESS:
            return{...state,
                isProcessing:false,
                claseCreated:true,
                clase:action.payload
            }
        
    //DELETE
        case actions.CLASE_DELETE_INIT:
             return {
                ...state,                
                deleteItem: action.payload //ID
             }
        case actions.CLASE_DELETE_SUCCESS:
            return {
                ...state,
                clases: /*action.payload*/ state.clases.filter(clase => clase.id !== state.deleteItem), //actualiza el state eliminando el editado
                deleteItem: null
            }
        case actions.CLASE_DELETE_ERROR:
            return {
               ...state,                
                error: action.payload //true
            }
    
    //EDIT
    case actions.CLASE_EDIT_INIT:
        return {
           ...state,
           saving: true,                
           editItem: action.payload //ID OR OBJECT?
        }   
    case actions.CLASE_EDIT_SUCCESS:
        return {
            ...state,
            saving: false, 
            clase: state.clase.map( clase =>
                clase.id === action.payload.id ? clase = action.payload : clase
            ) //actualiza el nodo por todo el objeto editado nuevo, para que las clases se actualicen en la lista
            
        }
   case actions.CLASE_EDIT_ERROR:
       return {
          ...state,                
           error: action.payload //true
        }
        default:
            return state
            break;
    }
}