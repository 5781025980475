import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

export const EmptyBox = ( {parent, place})=>{
    const materia = useParams()
    
    return (
        <div className="empty-clase-box">
            
            <div className="clase">
                <Link 
                to={`/panel/clase/add`}>
                    <div className="row content-wrapper">
                        <div className="col-sm-6 bordered placeholder">
                            <div className="number">
                                {place}
                            </div>
                        </div>
                        <div className="col-sm-6 text-left text-col">
                            <div class="title">Contenido semana {place}</div>
                            <div class="date"> </div>
                        </div>
                    </div>              
                </Link>                                    
            </div>
            <br></br>
        </div>
    )
}

export default EmptyBox