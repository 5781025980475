import React from 'react';
import { useParams} from 'react-router-dom';
import FichaPerfil from '../Profile/FichaPerfil'

export const EstudianteDetail = (props) => {
   
   const parameters = useParams()

    const userID                            = parameters.id
    const role                              = "estudiante"
    const fromPerfil                        = true
    const pestañasArray = ['Historial de Pagos', 'Documentos', 'Desempeño Academico']
    const {match} = props
    

    return (
        <>
    
            <h2>Estudiante Detalle</h2>
            
            <FichaPerfil
                {...props} 
                userID={userID}
                role={role}
                fromPerfil={fromPerfil}
                asAdmin={true}
                pestañas={pestañasArray}
            />
            
        </>
    )
}