import React from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Messages from '../../../Helpers/Messages'


const validation = ({nameField='', emailField=''}) => {
    let error = {}
    let isError = false
    if (nameField.trim() === ''){
        error.nameField ='Required'
        isError = true;
    }
    if (nameField.length > 20){
        error.nameField ='Too long'
        isError = true;
    }
    if (emailField.trim() === ''){
        error.emailField ='Required'
        isError = true;
    }
    
    if (isError){
        throw new SubmissionError(error)
    } else {
        console.log('Valid submited form')
    }
    //console.log(nameField)
}

const isRequiredF = (value) => (
    !value && Messages.Validation__Required
);
const isNumber =  (value) => (
    isNaN(Number(value)) && Messages.Validation__Number
)

const renderField = ({ label, input, type="text", placeholder, meta: {touched, error}}) => (
    <div className="campo">
        <label>{label}</label>
        <input {...input} className="form-control" type={type} placeholder={placeholder} />
        {touched && error &&
            <span className="error">{error}</span>
        }
    </div>
)
const selectionField = ({ label, input, options, placeholder, meta: {touched, error}}) => (
    <div className="campo">
        <label htmlFor={`select ${label}`}>{label}</label>
        <div className="select">
        <select {...input} id={`select ${label}`} placeholder={placeholder}>
            <option className="placeholder">Seleccionar</option>

            {options.map(option => <option 
                                        key={label === "Especialidad" ? option.id : option}
                                        value={label === "Especialidad" ? option.id : option}>
                                        {label === "Especialidad" ? option.titulo : option}
                                    </option>
            )}
        </select>
        </div>
        
        {touched && error &&
            <span className="error">{error}</span>
        }
    </div>
)


//la validacion funciona con validacion individual de campos
//o con funcion handleSumbit(), actualmente viene funcion como parametro
//desde el componente padre (InscricionAdd)

const AddFormFunc = ({handleSubmit, submitting, especialidades, semestres}) => (
            <form id="InscripcionAdd" action="" 
            //onSubmit={handleSubmit()}            
            onSubmit={handleSubmit}
            >
                <div className="form-group">
                    <Field  
                        name="nombreField"      
                        component={renderField}
                        label="Nombre"
                        type="text"
                        validate={isRequiredF}
                         ></Field>
                    <Field 
                        name="apellidoField"    
                        component={renderField}
                        type="text"
                        validate={isRequiredF}
                        label="Apellido"></Field>
                    
                    <Field 
                        name="cedulaField"    
                        component={renderField}
                        type="number"
                        validate={[isNumber, isRequiredF]}
                        label="Cedula"></Field>
                    {/* <Field 
                        name="especialidadField"    
                        component={renderField}
                        type="selecti"
                        validate={isRequiredF}
                        label="Especialidad"></Field> */}
                    <Field 
                        name="especialidadField"    
                        component={selectionField}
                        type="select"
                        validate={isRequiredF}
                        label="Especialidad"
                        options={especialidades}>   
                        </Field>
                    <Field 
                        name="semestreField"    
                        component={selectionField}
                        type="select"
                        validate={isRequiredF}
                        label="Semestre"
                        options={semestres}
                        >
                        </Field>
                    {/* <Field 
                        name="semestreField"    
                        component={selectionField}
                        type="select"
                        validate={[isNumber, isRequiredF]}
                        label="Semestre"></Field> */}
                    <button
                        type="submit"
                        disabled={submitting}
                        className="submit">
                        Crear Inscripción
                    </button>
                </div>
            </form>

)
AddFormFunc.propTypes={
    nameField: PropTypes.string,
    emailField: PropTypes.string,
    apellidoField:PropTypes.string,
    cedulaField:PropTypes.string,

}

/*
AddFormFunc.prototype = {
    handleSubmit: PropTypes.func.isRequired
}*/

const InscripcionAddForm = reduxForm({ 
    form: 'AddFormFunc',
    validation
})(AddFormFunc)

export default connect(
    (state, props) => ({ initialValues: props }))(InscripcionAddForm)

/*
const AddFormFuncConnect = connect(
    state, props,
) => ({ initialValues: props })
(reduxForm({
    form: 'AddFormFunc',
    validation,
    onSubmit: values => values
})(AddFormFunc))
export default AddFormFuncConnect*/

/*
export default reduxForm({ 
    form: 'AddFormFunc',
    validation
})(AddFormFunc);*/