import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import BandejaMensajes from './BandejaMensajes'
import MainChat from './MainChat'
import FichaChat from './FichaChat'
import Empty from './Empty'
import * as elems from '../../../AFakeData/myfakedata'
import * as Constants from '../../../Constants/Constants'

export const MensajesPage = (props) => {


    const userID = localStorage.getItem(Constants.TOKEN_SESSION_USER_ID)
    const activeChat = useSelector(state => state.mensajesReducer.activeChat)
    const loading = useSelector(state => state.mensajesReducer.isProcessing)
    const error = useSelector(state => state.mensajesReducer.error)

    

    function ContentByEntity(id){//entidad,activeChat, error, loading){
        if(id) return <FichaChat activeChat={activeChat} error={error} loading={loading} />
        return <Empty />
    //     switch(entidad){
    //         case 'profesor':
    //             return <FichaChat isProfesor={true} activeChat={activeChat} error={error} loading={loading} />
    //         case 'estudiante':
    //             return <FichaChat activeChat={activeChat} error={error} loading={loading} />
    //         default:
    //             return <Empty />
    //     }
        
        

    }
    return (
        <div className="mensajes-layout mgntop">
            <div className="box">
                
                <h2>Mensajes</h2>
                <div className="d-flex mensajes-wrapper">
                    < BandejaMensajes />
                    < MainChat activeChat={activeChat} userID={userID} />
                </div> 
            
                
            </div>
            {ContentByEntity(activeChat.id)}
            
        </div>
    )
}