import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../Actions/EstudiantesActions'
import {LoadingMessage} from '../../../Helpers/LoadingMessage'
import * as Messages from  '../../../Helpers/Messages'
import * as Constants from '../../../Constants/Constants';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faEye } from '@fortawesome/free-solid-svg-icons'

export const Estudiantes = ({ estudiante, handleClickPreview }) => {

    const dispatch = useDispatch()
    const periodoID = 1
    useEffect(() => {
        const getEstudiantes = () => dispatch(actions.getEstudiantesAllAction(periodoID))
        getEstudiantes()
    }, [])

    const estudiantesListArray = useSelector(state => state.estudianteReducer.estudiantes)
    //const estudiantesListArray = Array.from (estudiantesList)

    const error = useSelector (state => state.estudianteReducer.error)
    const loading = useSelector(state => state.estudianteReducer.isProcessing)
   
    // Definir Proptypes 
    /*const [selectedEstudiante, updatePreview] = useState({
        estudiante:''
    }); 
    const previewObject = (selectedEstudiante) => {
        console.log("from Estu", selectedEstudiante)
        //updatePreview(selectedEstudiante)
    }*/
    
    return (
        <Fragment>
            { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null } 
            { loading ? <LoadingMessage /> : null }
            { estudiantesListArray.length === 0 ? Messages.ESTUDIANTES_LIST_ADMIN__EMPTY : (
                <>
                <table className="table table-hover list table-4 table-fixed ">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Especialidad</th>
                                <th scope="col">Semestre</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                </table>
                <div className="wrapper-for-overflow">
                    <table className="table table-hover list table-4 table-overflow">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Especialidad</th>
                                <th scope="col">Semestre</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                estudiantesListArray.map( (estudiante, index) => (
                                    <tr key={index}>
                                        <td>
                                            <img src="/img/default_userpic.gif" 
                                            className="rounded-circle userpic-list"
                                            alt="avatar image" />
                                            <span className="capitalize">{estudiante.nombre.toLowerCase()} </span> 
                                            <span className="capitalize">{estudiante.apellido.toLowerCase()}</span>
                                        </td>
                                        <td>
                                            <span className="badge badge-grafico">Gráfico</span>
                                        </td>
                                        <td>4</td>
                                        <td>
                                            <Link to={Constants.Link_estudiantes__detail+estudiante.id}>
                                                <img className="action-icon" 
                                                alt="Editar" 
                                                src="../../icons/edit.svg" 
                                                />
                                            </Link>
                                            <a href="#" 
                                                onClick={() => handleClickPreview(estudiante)}
                                                ><img className="action-icon" 
                                                alt="Ver" src="../../icons/view.svg" 
                                            /></a>
                                        </td>
                                    </tr>
                                ))
                            }
                            
                        </tbody>
                    </table>
                </div> 
                </>
            )}
        </Fragment>
    )
}