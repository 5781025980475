import React, { Component, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import * as actions from '../../Actions/UserActions';
import { history } from '../../Helpers/History'
import { connect } from 'react-redux'
import * as Messages from '../../Helpers/Messages';
import LoginRecuperacion from './LoginRecuperacion'
import { Redirect, Route } from "react-router";

export const AreaLogin = ({ handleSuccessfulAuth }) => {


    const dispatch = useDispatch();
    
    //Estado Inicial de Sesion
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [recuperacionShowed, setRecuperacionShowed] = useState(null)
//    const [isError, resetErrors] = useState({ isError: false });

    const handleSubmit = (e) => { 
        e.preventDefault();
        const dataFields = { username, password }
        dispatch(actions.initLoginAction(dataFields))
    }
    
    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
        //resetErrors(false)
    };
    
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
        //resetErrors(false)
    };


    
    const processing    =  useSelector (state => state.userReducer.isProcessing )
    const isError       =  useSelector (state => state.userReducer.isError ) 
    const errorMessage  =  useSelector (state => state.userReducer.errorMessage )
    const isLoggedIn    =  useSelector (state => state.userReducer.loggedIn )
    
    //If Login successful, redirect
    useEffect(() => {
        if (isLoggedIn === true ) {
            history.push("/panel") 
        } else {
            console.log("changed but False")
            //check if Logged in 
            dispatch(actions.checkIfLogged())
        }
    }, [isLoggedIn])

    return (
        <>
           <div className="idv-box position-absolute bg-white text-dark text-center">
                <img className="idv-logo d-inline text-center" 
                alt="Instituto de Diseño de Valencia" 
                src="logo/instituto-diseno-valencia-full-logo.svg"  />
                <h1>Te damos la Bienvenida</h1>
                <div>Ingresa tu usuario o email</div>  
                <form onSubmit={handleSubmit}>
                <div className="form-label-group"> 
                    <input 
                        type="text"
                        name="username" 
                        id="login-input-username" 
                        className="form-control" 
                        onChange= { onChangeUsername }
                        placeholder={Messages.LOGIN__USERNAME}
                        required="required"
                        /*value={this.state.username}*/
                            />
                        <label htmlFor="login-input-username">{Messages.LOGIN__USERNAME}</label>
                    </div>
                    <div className="form-label-group">
                        <input 
                            type="password"
                            name="password" 
                            id="login-input-password" 
                            className="form-control" 
                            onChange= { onChangePassword }
                            placeholder={ Messages.LOGIN__PASSWORD} 
                            required="required"
                            /*ref={inputElement => this.inputPassword = inputElement}*/
                            /*value={this.state.password}*/
                            />
                        <label htmlFor="login-input-password">{Messages.LOGIN__PASSWORD}</label>
                    </div>
                    { isError ? <p className="success alert-warning">{errorMessage}</p>: null }
                        <input 
                            type="submit" 
                            className="btn btn-primary btn-login"     
                            value="Ingresar"
                            disabled={processing} />
                </form>
                <Link to={`/recuperacion-de-contraseña`}><p className="link">Olvidé mi contraseña</p></Link>
            </div>
            
        </>
    )

}