import React, {useState, useEffect} from 'react'

export const Progreso = ({ estudiante }) => {

    const [num, setNum] = useState(0)

    //Esta variable se refiere a la relacion entre lo largo del level de progreso (en px) y el porcentaje
    const relacion = 1.5

    // useEffect(()=>{

    //     setProgreso(estudiante)

    // }, [])

    // const setProgreso = (estudiante) => {
    //     let newNum = 25
    //     if(estudiante.id_periodo){
    //         newNum = 100
    //     } else if(estudiante.id_grupo){
    //         newNum = 75
    //     } else if(estudiante.id_pagos){
    //         newNum = 50
    //     }

    //     setNum(newNum)

    // }

    return(
        <div className="progreso">
            <div className="progreso-box grayed-out">
                <div className="progreso-level" style={{width:`${num * relacion}px`}}></div>
            </div>
            {num}%
        </div>
        
    )
}
export default Progreso