import React, { Component, useEffect } from 'react';
import EstudiantesInfo from '../Estudiantes/EstudiantesInfoMateria'
// import * as elems from  '../../../AFakeData/myfakedata'

export const MateriaInfo = (materiaObj) => {

    const materia = materiaObj.materia
    return (
        <>
            <div className="materia-title">{materia.nombre}</div> 
            <div className="especialidad">{materia.especialidad}</div>
            <div className="horario">
                {materia.horario ? (materia.horario.map(dia =>(<span>|      {dia.dia}: {dia.entrada} - {dia.salida}     |</span> ))) : null}
            </div>

            <EstudiantesInfo materiaID={materia.id} />
            
        </>
    )
}