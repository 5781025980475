import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../Actions/ProfesoresActions'
import {LoadingMessage} from '../../../Helpers/LoadingMessage'
import * as Messages from  '../../../Helpers/Messages'
import * as Constants from '../../../Constants/Constants';
import { Link } from 'react-router-dom';

export const ProfesoresList = ({handleClickPreview }) => {

    const dispatch = useDispatch()

    useEffect(() => {
        const getProfesoresAll = () => dispatch(actions.getProfesoresAll())
        getProfesoresAll()
    }, [])

    const profesoresListArray = useSelector(state => state.profesorReducer.profesores)
    const error = useSelector (state => state.profesorReducer.error)
    const loading = useSelector(state => state.profesorReducer.isProcessing)
  
    return (
        <Fragment>
            { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null } 
            { loading ? <LoadingMessage /> : null }
            { profesoresListArray.length === 0 ? Messages.PROFESORES_LIST_ADMIN__EMPTY : (
                <>
                <table className="table table-hover list table-4 table-fixed ">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Especialidad</th>
                                <th scope="col">Semestre</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                </table>
                <div className="wrapper-for-overflow">
                    <table className="table table-hover list table-4 table-overflow">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Especialidad</th>
                                <th scope="col">Semestre</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                profesoresListArray.map( (profesor, index) => (
                                    <tr key={index}>
                                        <td>
                                            <img src="/img/default_userpic.gif" 
                                            className="rounded-circle userpic-list"
                                            alt="avatar image" />
                                            <span className="capitalize">{profesor.nombre.toLowerCase()} </span> 
                                            <span className="capitalize">{profesor.apellido.toLowerCase()}</span>
                                        </td>
                                        <td>
                                            <span className="badge badge-grafico">Gráfico</span>
                                        </td>
                                        <td>4</td>
                                        <td>
                                            {/* <Link to={Constants.Link_profesors__detail + profesor.id}> */}
                                                <img className="action-icon" 
                                                alt="Editar" 
                                                src="../../icons/edit.svg" 
                                                />
                                            {/* </Link> */}
                                            <a href="#" 
                                                onClick={() => handleClickPreview(profesor)}
                                                ><img className="action-icon" 
                                                alt="Ver" src="../../icons/view.svg" 
                                            /></a>
                                        </td>
                                    </tr>
                                ))
                            }
                            
                        </tbody>
                    </table>
                </div> 
                </>
            )}
        </Fragment>
    )
}