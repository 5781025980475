import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../Actions/PeriodoActions'
import * as Constants from '../../../Constants/Constants'
import Horarios from './Horarios'
import HorariosSemestre from './HorariosSemestre'
import HorariosCarrera from './HorariosCarrera'

export const HorariosPage = () => {


    // HOOKS
    const dispatch                  = useDispatch()
    const [stage, setStage]         = useState('carrera')
    const [component, setComponent] = useState(null)

    useEffect(()=>{
        const newComponent = PageByStage(stage)
        setComponent(newComponent)
    }, [stage])

    useEffect(()=>{
        getLocalCarreras()
    },[])




    // FUNCTIONS
    const getLocalCarreras = () => {
        const carreras    = JSON.parse(localStorage.getItem('SELECTED_CARRERAS'))
        const carrerasSem = JSON.parse(localStorage.getItem('CARRERAS_SEMESTRE_MATERIA'))
        dispatch(actions.setNewCarrerasSemestres(carrerasSem))
        dispatch(actions.setCarrerasAllLocallyAction(carreras.carreras))

    }

    
    const showThisComponent = newStage => setStage(newStage)

    
    const PageByStage = (thisStage) => {
        switch(thisStage) {
            case "carrera":
                return <HorariosCarrera handleSelection={showThisComponent} />
            // case "semestre":
            //     return  <HorariosSemestre handleSelection={showThisComponent} />
            case "horarios":
                return <Horarios handleSelection={showThisComponent} />
            default:
                return <HorariosCarrera handleSelection={showThisComponent} />
        }
    }

    return (
        <div className="container mgntop">
            {component}
        </div>
    )
}

export default HorariosPage