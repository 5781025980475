import React from 'react'
import PropTypes from 'prop-types';
import * as Constants from '../../../Constants/Constants'
import FichaPerfil from './FichaPerfil'



export const ProfileLayout = (props) =>{

    const role                              = localStorage.getItem(Constants.TOKEN_SESSION_ROLE)
    const userID                            = localStorage.getItem(Constants.TOKEN_SESSION_USER_ID)
    const fromPerfil                        = true
    const pestañasArray                     = ['Seguridad']

    

    return (
        <>
            
            <div className="profile-layout">
                
                <div className="">
                    <h3 className="profile-title">Perfil</h3>
                </div>
                <FichaPerfil
                    {...props} 
                    userID={userID}
                    role={role}
                    fromPerfil={fromPerfil}
                    pestañas={pestañasArray}
                />
            </div>
        </>
    )
}

export default ProfileLayout