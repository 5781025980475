// import React, {PureComponent } from 'react';
// import Dropzone from 'react-dropzone';

import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';


export const NewUploader = ({handleOnSave}) => {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
    const [imgSrc, setImgSrc] = useState(null)


    const files = acceptedFiles.map(file => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setImgSrc(reader.result)
            handleOnSave(reader.result)
        });
        reader.readAsDataURL(file);
        return (
            <li key={file.path}>
                {file.path} - {file.size} bytes
                <img src={imgSrc} style={{width:"100px", height:"100px"}} alt="Preview" />

            </li>
        )}
    );

    return (
        <section className="container">
        <div {...getRootProps({className: 'custom-uploader'})}>
            <input {...getInputProps()} />
            <p className="text">Drag 'n' drop some files here, or click to select files</p>
        </div>
        <aside>
            <h4>Files</h4>
            <ul>{files}</ul>
        </aside>
        </section>
    );
}

export default NewUploader
