//ACTIONS
//USER
export const LOGIN_USER_INIT                       = 'LOGIN_USER_INIT';
export const LOGIN_USER_SUCCESS                    = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR                      = 'LOGIN_USER_ERROR';
export const LOGOUT_USER_ACTION                    = 'LOGOUT_USER_ACTION';


export const USER_GET_DATA_INIT                    = 'USER_GET_DATA_INIT'
export const USER_GET_DATA_SUCCESS                 = 'USER_GET_DATA_SUCCESS'
export const USER_GET_DATA_ERROR                   = 'USER_GET_DATA_ERROR'

export const USER_SAVE_DATA_INIT                    = 'USER_SAVE_DATA_INIT'
export const USER_SAVE_DATA_SUCCESS                 = 'USER_SAVE_DATA_SUCCESS'
export const USER_SAVE_DATA_ERROR                   = 'USER_SAVE_DATA_ERROR'

export const USER_SAVE_PIC_INIT                    = 'USER_SAVE_PIC_INIT'
export const USER_SAVE_PIC_SUCCESS                 = 'USER_SAVE_PIC_SUCCESS'
export const USER_SAVE_PIC_ERROR                   = 'USER_SAVE_PIC_ERROR'

export const USER_SAVE_DATA_TO_FALSE                = "USER_SAVE_DATA_TO_FALSE"
export const USER_SAVE_PIC_TO_FALSE                = "USER_SAVE_DATA_TO_FALSE"

export const USER_CHANGE_PASSWORD_INIT             = 'USER_CHANGE_PASSWORD_INIT'
export const USER_CHANGE_PASSWORD_SUCCESS          = 'USER_CHANGE_PASSWORD_SUCCESS'
export const USER_CHANGE_PASSWORD_ERROR            = 'USER_CHANGE_PASSWORD_ERROR'
export const USER_RESET_PASSWORD_SUCCESS           = 'USER_RESET_PASSWORD_SUCCESS'

export const USER_CHANGE_SAVED_TO_FALSE            = 'USER_CHANGE_SAVED_TO_FALSE'

export const USER_RECUPERACION_EMAIL_INIT             = 'USER_RECUPERACION_EMAIL_INIT'
export const USER_RECUPERACION_EMAIL_SUCCESS          = 'USER_RECUPERACION_EMAIL_SUCCESS'
export const USER_RECUPERACION_EMAIL_ERROR            = 'USER_RECUPERACION_EMAIL_ERROR'

export const AUTHENTICATE_TOKEN_INIT             = 'AUTHENTICATE_TOKEN_INIT'
export const AUTHENTICATE_TOKEN_SUCCESS          = 'AUTHENTICATE_TOKEN_SUCCESS'
export const AUTHENTICATE_TOKEN_ERROR            = 'AUTHENTICATE_TOKEN_ERROR'

//INSCRIPCION
export const INSCRIPCION_ADD_ESTUDIANTE_INIT         = 'INSCRIPCION_ADD_ESTUDIANTE_INIT';
export const INSCRIPCION_ADD_ESTUDIANTE_SUCCESS      = 'INSCRIPCION_ADD_ESTUDIANTE_SUCCESS';
export const INSCRIPCION_ADD_ESTUDIANTE_ERROR        = 'INSCRIPCION_ADD_ESTUDIANTE_ERROR';
export const INSCRIPCIONES_GET_ALL_INIT              = 'INSCRIPCIONES_GET_ALL_INIT';
export const INSCRIPCIONES_GET_ALL_SUCCESS           = 'INSCRIPCIONES_GET_ALL_SUCCESS';
export const INSCRIPCIONES_GET_ALL_ERROR             = 'INSCRIPCIONES_GET_ALL_ERROR';
export const INSCRIPCION_GET                         = 'INSCRIPCION_GET';
export const INSCRIPCION_UPDATE                      = 'INSCRIPCION_UPDATE';

export const INSCRIPCION_UPDATE_DOCS_STATE           = 'INSCRIPCION_UPDATE_DOCS_STATE'
export const INSCRIPCIONES_DOCUMENTOS_GET_ALL_INIT              = 'INSCRIPCIONES_DOCUMENTOS_GET_ALL_INIT';
export const INSCRIPCIONES_DOCUMENTOS_GET_ALL_SUCCESS           = 'INSCRIPCIONES_DOCUMENTOS_GET_ALL_SUCCESS';
export const INSCRIPCIONES_DOCUMENTOS_GET_ALL_ERROR             = 'INSCRIPCIONES_DOCUMENTOS_GET_ALL_ERROR';
export const INSCRIPCIONES_DOCUMENTOS_SAVE_INIT                 = "INSCRIPCIONES_DOCUMENTOS_SAVE_INIT"
export const INSCRIPCIONES_DOCUMENTOS_SAVE_SUCCESS              = "INSCRIPCIONES_DOCUMENTOS_SAVE_SUCCESS"
export const INSCRIPCIONES_DOCUMENTOS_SAVE_ERROR                = "INSCRIPCIONES_DOCUMENTOS_SAVE_ERROR"
export const SAVED_DOCS_TO_FALSE                                = "SAVED_DOCS_TO_FALSE"

//MATERIAS
export const MATERIAS_GET_ALL_INIT              = 'MATERIAS_GET_ALL_INIT'
export const MATERIAS_GET_ALL_SUCCESS           = 'MATERIAS_GET_ALL_SUCCESS'
export const MATERIAS_GET_ALL_ERROR             = 'MATERIAS_GET_ALL_ERROR'

export const MATERIAS_GET_BY_USER_INIT     = 'MATERIAS_GET_BY_USER'
export const MATERIAS_GET_BY_USER_SUCCESS  = 'MATERIAS_GET_BY_USER_SUCCESS'
export const MATERIAS_GET_BY_USER_ERROR    = 'MATERIAS_GET_BY_USER_ERROR'

export const MATERIA_GET_BY_ID_INIT             = 'MATERIA_GET_BY_ID_INIT'
export const MATERIA_GET_BY_ID_SUCCESS          = 'MATERIA_GET_BY_ID_SUCCESS'
export const MATERIA_GET_BY_ID_ERROR            = 'MATERIA_GET_BY_ID_ERROR'

export const MATERIA_DELETE_INIT            = 'MATERIA_DELETE_INIT'
export const MATERIA_DELETE_SUCCESS         = 'MATERIA_DELETE_SUCCESS'
export const MATERIA_DELETE_ERROR           = 'MATERIA_DELETE_ERROR'

//CLASES
//GET/all
//GET/:id
//EDIT/:id
//ADD/
//DELETE/:id

export const CLASES_GET_BY_MATERIA_INIT      = 'CLASES_GET_BY_MATERIA_INIT'
export const CLASES_GET_BY_MATERIA_SUCCESS   = 'CLASES_GET_BY_MATERIA_SUCCESS'
export const CLASES_GET_BY_MATERIA_ERROR     = 'CLASES_GET_BY_MATERIA_ERROR'

export const CLASE_GET_BY_ID_INIT             = 'CLASE_GET_BY_ID_INIT'
export const CLASE_GET_BY_ID_SUCCESS          = 'CLASE_GET_BY_ID_SUCCESS'
export const CLASE_GET_BY_ID_ERROR            = 'CLASE_GET_BY_ID_ERROR'

export const CLASE_ADD_INIT                = 'CLASE_ADD_INIT'
export const CLASE_ADD_SUCCESS             = 'CLASE_ADD_SUCCESS'
export const CLASE_ADD_ERROR               = 'CLASE_ADD_ERROR'

export const CLASE_DELETE_INIT              = 'CLASE_DELETE_INIT'
export const CLASE_DELETE_SUCCESS           = 'CLASE_DELETE_SUCCESS'
export const CLASE_DELETE_ERROR             = 'CLASE_DELETE_ERROR'

export const CLASE_EDIT_INIT               = 'CLASE_EDIT_INIT'
export const CLASE_EDIT_SUCCESS            = 'CLASE_EDIT_SUCCESS'
export const CLASE_EDIT_ERROR              = 'CLASE_EDIT_ERROR'

//ESTUDIANTES
export const ESTUDIANTES_GET_ALL_INIT      = 'ESTUDIANTES_GET_ALL_INIT'
export const ESTUDIANTES_GET_ALL_SUCCESS   = 'ESTUDIANTES_GET_ALL_SUCCESS'
export const ESTUDIANTES_GET_ALL_ERROR     = 'ESTUDIANTES_GET_ALL_ERROR'

//Esta parte es la agregada recientemente para el componente
//de estudiantes info

//REVISAR: Es posible que ambos llamados para estudiantes puedan integrarse
//en algunos actions: INIT y ERROR
export const ESTUDIANTES_GET_BY_MATERIA_INIT             = 'ESTUDIANTE_GET_BY_MATERIA_INIT'
export const ESTUDIANTES_GET_BY_MATERIA_SUCCESS          = 'ESTUDIANTE_GET_BY_MATERIA_SUCCESS'
export const ESTUDIANTES_GET_BY_MATERIA_ERROR            = 'ESTUDIANTE_GET_BY_MATERIA_ERROR'

export const ESTUDIANTE_GET_BY_ID_INIT             = 'ESTUDIANTE_GET_BY_ID_INIT'
export const ESTUDIANTE_GET_BY_ID_SUCCESS          = 'ESTUDIANTE_GET_BY_ID_SUCCESS'
export const ESTUDIANTE_GET_BY_ID_ERROR            = 'ESTUDIANTE_GET_BY_ID_ERROR'

export const ESTUDIANTE_ADD_INIT                = 'ESTUDIANTE_ADD_INIT'
export const ESTUDIANTE_ADD_SUCCESS             = 'ESTUDIANTE_ADD_SUCCESS'
export const ESTUDIANTE_ADD_ERROR               = 'ESTUDIANTE_ADD_ERROR'

export const ESTUDIANTE_DELETE_INIT              = 'ESTUDIANTE_DELETE_INIT'
export const ESTUDIANTE_DELETE_SUCCESS           = 'ESTUDIANTE_DELETE_SUCCESS'
export const ESTUDIANTE_DELETE_ERROR             = 'ESTUDIANTE_DELETE_ERROR'

export const ESTUDIANTE_EDIT_INIT               = 'ESTUDIANTE_EDIT_INIT'
export const ESTUDIANTE_EDIT_SUCCESS            = 'ESTUDIANTE_EDIT_SUCCESS'
export const ESTUDIANTE_EDIT_ERROR              = 'ESTUDIANTE_EDIT_ERROR'


//NOTAS 
export const NOTAS_GET_BY_MATERIA_INIT               = 'NOTAS_GET_BY_MATERIA_INIT'
export const NOTAS_GET_BY_MATERIA_SUCCESS            = 'NOTAS_GET_BY_MATERIA_SUCCESS'
export const NOTAS_GET_BY_MATERIA_ERROR              = 'NOTAS_GET_BY_MATERIA_ERROR'

export const NOTAS_GET_BY_CLASE_INIT               = 'NOTAS_GET_BY_CLASE_INIT'
export const NOTAS_GET_BY_CLASE_SUCCESS            = 'NOTAS_GET_BY_CLASE_SUCCESS'
export const NOTAS_GET_BY_CLASE_ERROR              = 'NOTAS_GET_BY_CLASE_ERROR'

export const NOTAS_GET_BY_ESTUDIANTE_INIT               = 'NOTAS_GET_BY_ESTUDIANTE_INIT'
export const NOTAS_GET_BY_ESTUDIANTE_SUCCESS            = 'NOTAS_GET_BY_ESTUDIANTE_SUCCESS'
export const NOTAS_GET_BY_ESTUDIANTE_ERROR              = 'NOTAS_GET_BY_ESTUDIANTE_ERROR'

export const NOTA_EDIT_ESTUDIANTE_INIT                 = 'NOTA_EDIT_ESTUDIANTE_INIT'
export const NOTA_EDIT_ESTUDIANTE_SUCCESS              = 'NOTA_EDIT_ESTUDIANTE_SUCCESS'
export const NOTA_EDIT_ESTUDIANTE_ERROR                = 'NOTA_EDIT_ESTUDIANTE_ERROR'


//MENSAJES

export const MENSAJES_GET_BY_USERS_INIT             = 'MENSAJES_GET_BY_USERS_INIT'
export const MENSAJES_GET_BY_USERS_SUCCESS          = 'MENSAJES_GET_BY_USERS_SUCCESS'
export const MENSAJES_GET_BY_USERS_ERROR            = 'MENSAJES_GET_BY_USERS_ERROR'

export const MENSAJES_ACTIVE_CHAT_INIT             = 'MENSAJES_ACTIVE_CHAT_INIT'
export const MENSAJES_ACTIVE_CHAT_SUCCESS          = 'MENSAJES_ACTIVE_CHAT_SUCCESS'
export const MENSAJES_ACTIVE_CHAT_ERROR            = 'MENSAJES_ACTIVE_CHAT_ERROR'

export const MENSAJES_GET_PROFESORES_INIT             = 'MENSAJES_GET_PROFESORES_INIT'
export const MENSAJES_GET_PROFESORES_SUCCESS          = 'MENSAJES_GET_PROFESORES_SUCCESS'
export const MENSAJES_GET_PROFESORES_ERROR            = 'MENSAJES_GET_PROFESORES_ERROR'

export const MENSAJES_GET_ESTUDIANTES_INIT             = 'MENSAJES_GET_ESTUDIANTES_INIT'
export const MENSAJES_GET_ESTUDIANTES_SUCCESS          = 'MENSAJES_GET_ESTUDIANTES_SUCCESS'
export const MENSAJES_GET_ESTUDIANTES_ERROR            = 'MENSAJES_GET_ESTUDIANTES_ERROR'

export const MENSAJE_SEND_INIT                         = 'MENSAJE_SEND_INIT'
export const MENSAJE_SEND_SUCCESS                      = 'MENSAJE_SEND_SUCCESS'
export const MENSAJE_SEND_ERROR                        = 'MENSAJE_SEND_ERROR'


//PROFESORES
export const PROFESORES_GET_ALL_INIT             = 'PROFESORES_GET_ALL_INIT'
export const PROFESORES_GET_ALL_SUCCESS          = 'PROFESORES_GET_ALL_SUCCESS'
export const PROFESORES_GET_ALL_ERROR            = 'PROFESORES_GET_ALL_ERROR'


//PERIODO
export const PERIODO_GET_CARRERAS_ALL_INIT             = 'PERIODO_GET_CARRERAS_ALL_INIT'
export const PERIODO_GET_CARRERAS_ALL_SUCCESS          = 'PERIODO_GET_CARRERAS_ALL_SUCCESS'
export const PERIODO_GET_CARRERAS_ALL_ERROR            = 'PERIODO_GET_CARRERAS_ALL_ERROR'

export const PERIODO_GET_CARRERAS_ALL_LOCALLY          = 'PERIODO_GET_CARRERAS_ALL_LOCALLY'

export const PERIODO_CREATE_NEW_INIT                   = 'PERIODO_CREATE_NEW_INIT'
export const PERIODO_CREATE_NEW_SUCCESS                = 'PERIODO_CREATE_NEW_SUCCESS'
export const PERIODO_CREATE_NEW_ERROR                  = 'PERIODO_CREATE_NEW_ERROR'

export const CHANGE_CARRERAS_SELECTED                   = 'CHANGE_CARRERAS_SELECTED'
export const CHANGE_CARRERAS                            = 'CHANGE_CARRERAS'

export const MATERIAS_GET_BY_CARRERA_INIT                   = 'MATERIAS_GET_BY_CARRERA_INIT'
export const MATERIAS_GET_BY_CARRERA_SUCCESS                = 'MATERIAS_GET_BY_CARRERA_SUCCESS'
export const MATERIAS_GET_BY_CARRERA_ERROR                  = 'MATERIAS_GET_BY_CARRERA_ERROR'

export const MATERIAS_GET_BY_SEMESTRE_INIT                   = 'MATERIAS_GET_BY_SEMESTRE_INIT'
export const MATERIAS_GET_BY_SEMESTRE_SUCCESS                = 'MATERIAS_GET_BY_SEMESTRE_SUCCESS'
export const MATERIAS_GET_BY_SEMESTRE_ERROR                  = 'MATERIAS_GET_BY_SEMESTRE_ERROR'