import * as actions from '../Actions/ActionTypes'

const initialState ={
    carreras:[],
    materias: [],
    materiasSemestre: [],
    programas:[],
    carreraActual:{},
    carreraHorario:{},
    semestresHorario:[],
    savingMateriaSemetre:false,
    isProcessingSaved:false,
    errorSaved:false,
    isProcessingCarreras:false,
    errorCarreras:false,
}
  
export default function periodoReducer(state = initialState, action) {
    switch (action.type) {
        case actions.PERIODO_GET_CARRERAS_ALL_INIT:
            return {...state,                
                isProcessingCarreras: action.payload
            }
        case actions.PERIODO_GET_CARRERAS_ALL_SUCCESS:
            return {...state,                
                isProcessingCarreras: false,
                carreras: action.payload
            }
        case actions.PERIODO_GET_CARRERAS_ALL_ERROR:
            return {...state,                
                isProcessingCarreras: false,
                errorCarreras: action.payload
            }

        
        case actions.PERIODO_CREATE_NEW_INIT:
            return {...state,                
                isProcessingSaved: action.payload 
            }
        case actions.PERIODO_CREATE_NEW_SUCCESS:
            return {...state,                
                isProcessingSaved: false,
                saved: action.payload
            }
        case actions.PERIODO_CREATE_NEW_ERROR:
            return {...state,                
                isProcessingSaved: false,
                errorSaved: action.payload
            }

        case actions.MATERIAS_GET_BY_CARRERA_INIT:
            return {...state,                
                isProcessing: action.payload 
            }
        case actions.MATERIAS_GET_BY_CARRERA_SUCCESS:
            return {...state,                
                isProcessing: false,
                materias: action.payload
            }
        case actions.MATERIAS_GET_BY_CARRERA_ERROR:
            return {...state,                
                isProcessing: false,
                error: action.payload
            }

        case actions.MATERIAS_GET_BY_SEMESTRE_INIT:
            return {...state,                
                isProcessing: action.payload 
            }
        case actions.MATERIAS_GET_BY_SEMESTRE_SUCCESS:
            return {...state,                
                isProcessing: false,
                materiasSemestre: [...state.materiasSemestre, action.payload]
            }
        case actions.MATERIAS_GET_BY_SEMESTRE_ERROR:
            return {...state,                
                isProcessing: false,
                error: action.payload
            }


        case actions.PERIODO_GET_CARRERAS_ALL_LOCALLY:
            return {...state,                
                carreras: action.payload
            }
        case 'SET_NEW_CARRERA_SEMESTRES':
            return {...state,
                materiasSemestre: action.payload
            }
        case 'CARRERA_HORARIO':
            return {...state,
                carreraHorario: action.payload
            }
        case 'SEMESTRES_HORARIO':
            return {...state,
                semestresHorario: action.payload
            }
        case 'SET_ALL_MATERIAS':
            return {...state,
                materiasSemestre: action.payload
            }
        case 'SET_CARRERA':
            return {...state,                
                carreraActual: {...state.carreraActual,
                     id: action.payload.id, 
                     titulo: action.payload.titulo}
            }

        case 'SAVING_SELECTED_MATERIAS_SEMESTRE':
            return {...state,                
                savingMateriaSemetre: action.payload 
            }
        default:
            return state;
    }
}
