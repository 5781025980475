import React, { Component } from 'react';
//import { createStore, applyMiddleware } from 'redux';
import { connect } from 'react-redux'

import { BrowserRouter, Switch, Route, StaticRouter } from 'react-router-dom';

import * as Constants from './Constants/Constants';

import LoginPage  from './Pages/LoginPage/LoginPage';
import LayoutAdmin from './Pages/Dashboard/LayoutAdmin';
import LayoutProfesor from './Pages/Dashboard/LayoutProfesor';
import LayoutEstudiante from './Pages/Dashboard/LayoutEstudiante';
import ProfileLayout from './Pages/Dashboard/Profile/ProfileLayout';
import SignOut from './Pages/Dashboard/Profile/SignOut';
import { NotFound } from './Pages/NotFound';
import PasswordRecuperacion from './Pages/LoginPage/PasswordRecuperacion'
import LoginRecuperacion from './Pages/LoginPage/LoginRecuperacion'

import { history } from './Helpers/History'
import * as roles from './Constants/Roles'
//Actions
//import ProtectedRoute from './components/ProtectedRoute'


import './App.css';
import './css.css';
;

class App extends Component {

  constructor() {
    super();

    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  
 
  handleLogout() {
    this.setState({
      loggedStatus: "NOT_LOGGED_IN",
      user: {}
    });
  }
  handleLogin(data) {
    this.setState({
      loggedStatus: "LOGGED_IN",
    });
  }


  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route 
              exact path="/" 
              render= { props => (
                <LoginPage
                  {...this.props} //props
                  handleLogin     = {this.handleLogin}
                  handleLogout    = {this.handleLogout}
                  //loggedInStatus  = {this.state.loggedInStatus}                  
                  loggedStatus  = { this.props.loggedStatus }
                />
              )}
              />
              <Route path='/profile' render={(props) => <ProfileLayout {...props} /> } />
              <Route path='/panel' render={ (props) => { 
                  
                  switch (localStorage.getItem(Constants.TOKEN_SESSION_ROLE)) {
                    case roles.admin_role:
                      return <LayoutAdmin {...props} /> ;                   
                    case roles.profesor_role:
                      return <LayoutProfesor {...props} />;
                    default:
                      return <LayoutEstudiante {...props} />;
                  }
                }
              }/>
            <Route path='/account/authenticate/:token/:email' render={(props) => <PasswordRecuperacion {...props} /> } />
            <Route path='/recuperacion-de-contraseña' render={(props) => <LoginRecuperacion {...props} /> } />
            


            <Route path='/logout' render={(props) => <SignOut {...props} /> } />
            <Route component ={NotFound}  />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}
export default App