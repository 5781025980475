import React from 'react'

const InfoProfesor = ({isEditable, onChangeFake}) =>{
    return(
        <>
            <div className="title">Datos del representante</div>
            <div className="input-group">
                <label htmlFor="repNombre" className="form-label">Nombre</label>
                <input id="repNombre" name="repNombre" type="text" onChange={onChangeFake}
                disabled={!isEditable} />
            </div>
            
            <div className="input-group">
                <label htmlFor="repApellido" className="form-label">Apellido</label>
                <input id="repApellido" name="repApellido" type="text" onChange={onChangeFake}
                disabled={!isEditable}/>
            </div>
            
            <div className="input-group">
                <label htmlFor="repCedula" className="form-label">Cedula</label>
                <input id="repCedula" name="repCedula" type="text" onChange={onChangeFake}
                disabled={!isEditable}/>
            </div>
            
            <div className="input-group">
                <label htmlFor="repTelefono" className="form-label">Telefono</label>
                <input id="repTelefono" name="repTelefono" type="text" onChange={onChangeFake}
                disabled={!isEditable}/>
            </div>

            <div className="input-group">
                <label htmlFor="repCelular" className="form-label">Celular</label>
                <input id="repCelular" name="repCelular" type="text" onChange={onChangeFake}
                disabled={!isEditable}/>
            </div>
        </>
    )
}

export default InfoProfesor