import React from 'react';
import { useParams} from 'react-router-dom';
import FichaPerfil from '../Profile/FichaPerfil'

export const EstudianteEdit = (props) => {
   
    const parameters = useParams()
    console.log('Al menos aca llega')
    const userID                            = parameters.id
    console.log(userID)
    const role                              = "estudiante"
    const fromPerfil                        = true
    const pestañasArray = ['Historial de Pagos', 'Documentos', 'Desempeño Academico']
   
    

    return (
        <>
    
            <h2>Estudiante Edit</h2>
            
            <FichaPerfil
                {...props} 
                userID={userID}
                role={role}
                fromPerfil={fromPerfil}
                asAdmin={true}
                pestañas={pestañasArray}
                isEditable={true}
            />
            
        </>
    )
}

export default EstudianteEdit