import * as actions from '../Actions/ActionTypes'

const initialState = {
    estudiantes: [], //recordar cambiar esto a un obj
    estudiantesTotal: 0,
    isProcessing: false,
    editItem: null,
    deleteItem: null,
    saving: null,
    error: false
};
export default function estudianteReducer(state = initialState, action) {
    switch (action.type) {
        //GET ALL
            case actions.ESTUDIANTES_GET_ALL_INIT:
                return {...state,                
                    isProcessing: action.payload
                }
            case actions.ESTUDIANTES_GET_ALL_SUCCESS:
                return {...state,                
                    isProcessing: false,
                    estudiantes: action.payload,
                    error: false
                }
           
            case actions.ESTUDIANTES_GET_ALL_ERROR:
                return {...state,                
                    isProcessing: false,
                    error: action.payload
                }

            case actions.ESTUDIANTES_GET_BY_MATERIA_INIT:
                return {...state,
                    isProcessing:action.payload
                }

            case actions.ESTUDIANTES_GET_BY_MATERIA_SUCCESS:
                return {...state,
                    isProcessing: false,
                    estudiantes: action.payload,
                    error: false
                }

            case actions.ESTUDIANTES_GET_BY_MATERIA_ERROR:
                return {...state,
                    isProcessing: false,
                    error: action.payload
                }
                
            default:
                return state
                break;
        }
}