export const Validation__Required = "Por favor completa este campo, es obligatorio" 
export const Validation__Number = "Este campo debe ser un número ¿puedes revisarlo?" 

//GENERALES
export const CONTENIDO_ERROR    = "Ha ocurrido un error"
export const CONTENIDO_LOADING  = "Cargando..."

//MATERIAS
export const MATERIAS_LIST__EMPTY = "No tienes materias asignadas en este momento"
//CLASES
export const CLASES_LIST__EMPTY = "¿Que te parece si agregamos clases? Puedes agregar el contenido de cada semana al hacer click en las casillas vacías"
//ESTUDIANTES
export const ESTUDIANTES_LIST__EMPTY = "Aun no hay estudiantes en esta materia" //"Vaya, no hay estudiantes en esta lista"
export const ESTUDIANTES_LIST_ADMIN__EMPTY = "Aun no hay estudiantes en esta lista" //"Vaya, no hay estudiantes en esta lista"

//PROFESORES
export const PROFESORES_LIST_ADMIN__EMPTY = "Aun no hay profesores en esta lista" //"Vaya, no hay estudiantes en esta lista"


export const LOGIN__USERNAME = "Usuario o Email"
export const LOGIN__PASSWORD = "Contraseña"

//MENSAJES
export const NO_SELECTED_CONVERSATION = "Comienza una conversacion amistosa. Escribe un mensaje a amigos o profesores"



