import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../Actions/UserActions'
import { history } from '../../../Helpers/History'
import * as Messages from  '../../../Helpers/Messages'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
                            


const Seguridad = ({userID, fromPerfil, token }) =>{

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmation, setConfirmation] = useState('')
    const dispatch = useDispatch()

    const successfullyChanged = useSelector(state => state.userReducer.successfullyChanged)
    const loading = useSelector(state => state.userReducer.isProcessingPassword)
    const error = useSelector(state => state.userReducer.isErrorPassword)
    
    
    //Si el cambio es exitoso, redirige a panel
    useEffect(() => {
        if (fromPerfil !== true && successfullyChanged === true) {
            setTimeout(()=>{
                history.push("/panel") 
            }, 3000)
        }
        deleteValues()
    }, [successfullyChanged])
    

    const handleChange = (e) => {
        const inputName = e.target.id
        const inputValue = e.target.value
        if(inputName === 'old-password'){
           return setCurrentPassword(inputValue)
        }
        else if(inputName === 'password'){
            return setNewPassword(inputValue)
        }
        else{
            return setConfirmation(inputValue)
        }
    }


    const handlePasswordSubmit = (e) => {
        
        e.preventDefault()
        let passwordInfo = {
            nueva: newPassword,
            confirmacion: confirmation
        }

        if(token){
            passwordInfo.actual = token
            console.log('Esta pasando por token')
        }
        else{
            passwordInfo.actual = currentPassword
            console.log('Este va enseguida')
            setTimeout(()=>{
                dispatch(actions.changeSavedToFalse())
                console.log('este va 6seg despues')
            }, 6000)
        }
        dispatch(actions.changeUserPasswordAction(userID, passwordInfo))
        
        
        
    }

    const deleteValues = () => {
        setCurrentPassword("")
        setNewPassword("")
        setConfirmation("")   
    }
    return(
        <>
            {/* <div className="container mgntop"> */}
            {successfullyChanged ? (<div className="alert alert-success">La contraseña fue actualizada</div>) : null}
            { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
            { loading ? <LoadingMessage /> : null }

            <form className="profile-form password" onSubmit={handlePasswordSubmit}>

                { fromPerfil ? (
                <>
                <div className="title">Seguridad</div>
                <div className="input-group">
                    <label htmlFor="old-password">Contraseña actual</label>
                    <input onChange={handleChange} disabled={loading ? true : false} value={currentPassword} id="old-password" name="old-password" type="password" />
                </div>
                </>
                ) : null }
                

                <div className="input-group">
                    <label htmlFor="password">Nueva contraseña</label>
                    <input onChange={handleChange} disabled={loading ? true : false} value={newPassword} id="password" name="password" type="password" />  
                </div>
                
                
                <div className="input-group">
                    <label htmlFor="confirmation">Confirma tu nueva contraseña</label>
                    <input onChange={handleChange} disabled={loading ? true : false} value={confirmation} id="confirmation" name="confirmation" type="password" />
                </div>
                
                <button className="" type="submit">Actualizar contraseña</button>
            </form>
            {fromPerfil !== true && successfullyChanged === true  ? (<a href="/panel">Ir al panel</a>) : null}
            
        </>
    )
}

export default Seguridad