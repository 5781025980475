import React from 'react';

export const DashAdminPage = (props) => {

    return (
        <div className="container mgntop">
            <h2>Administracion Dashboard</h2> 
                    
        </div>
    )
}