import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../Actions/PeriodoActions'
import * as Constants from '../../../Constants/Constants'
import { LoadingMessage } from '../../../Helpers/LoadingMessage';
import MateriasByCarrera from './MateriasByCarrera'
import * as Messages from  '../../../Helpers/Messages'

export const MateriasPage = ({showThisComponent}) =>{
    
    //hooks
    
    const dispatch = useDispatch()

    useEffect(() => {
        loadCarrerasLocally()
    }, [])

    


    //state
    const [carreras, setCarreras]                   = useState([])
    const [carreraSelected, setCarreraSelected]     = useState({id:null, titulo:null})
    const materiasSemestreArray                     = useSelector(state => state.periodoReducer.materiasSemestre)
    const loading                                   = useSelector(state => state.periodoReducer.isProcessingCarreras)
    const error                                     = useSelector(state => state.periodoReducer.errorCarreras)



    //Por defecto seleccionar la primera carrera en la lista
    useEffect(() => {
        if(carreras.length !== 0) {
            setCarreraSelected(carreras[0])
            dispatch(actions.setActualCarrera(carreras[0]))
            
        }
    }, [carreras])
    





    //Funciones
    const loadCarrerasLocally = () => {
        const carreras = JSON.parse(localStorage.getItem('SELECTED_CARRERAS'))
        if(carreras !== undefined) setCarreras(carreras.carreras)
    }

    const selectCarrera = (e) => {
        e.preventDefault()
        const carrera = {id: e.target.id, titulo: e.target.dataset.titulo}
        setCarreraSelected(carrera)
        dispatch(actions.setActualCarrera(carrera))
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        const newCarrerasArray = materiasSemestreArray.map(carrera =>{
            const newSemestres = carrera.semestres.map(sem => {
                const materias = sem.materias.filter(mat => mat.selected !== undefined && mat.selected === true)
                return {semestre:sem.semestre, materias: materias}
                
            })
            return {carreraID: carrera.carreraID, semestres: newSemestres}
        })
        //console.log(newCarrerasArray)
        localStorage.setItem('CARRERAS_SEMESTRE_MATERIA', JSON.stringify(newCarrerasArray))
        localStorage.removeItem('PERIODO_STAGE');
        localStorage.setItem('PERIODO_STAGE', 'seleccion-horarios')
        showThisComponent('seleccion-horarios')
    }

    
    return(
        <div className="container materias-selection-page periodo">
            <div className="row">
                <div className="col-md-1 ">
                    <button onClick={()=>showThisComponent('creacion')}><div className="back-button"></div></button>
                </div>
                <div className="col-md-11">
                    <div className="title">Verifica</div>
                    <div className="d-flex flex-column align-items-start carreras-btn-wrapper">
                        <div className="">
                            { error ? <p className="alert alert-danger">{Messages.CONTENIDO_ERROR}</p> : null}
                            { loading ? <LoadingMessage /> : null }
                            {carreras.map(car => 
                            <button key={car.id} 
                            onClick={selectCarrera}
                            id={car.id}
                            data-titulo={car.titulo}
                            className={`carreras-btn ${carreraSelected.id === car.id ? "selected" : ""}`} >{car.titulo}</button>)}
                        </div>
                    </div>
                    <div className="semestre-wrapper">
                        
                    </div>
                    <MateriasByCarrera carreraID={carreraSelected.id} carreraTitulo={carreraSelected.titulo}/>
                    <button onClick={handleSubmit} className="materias-btn">Guardar materias</button>
                    
                </div>
            </div>
            
        </div>
    )
}

export default MateriasPage